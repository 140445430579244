<section>
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Welcome To Petfolio
                Dashboard</h2>
            <!-- <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
          </ol> -->
        </div>

        <!-- <div class="d-flex">
          <div class="justify-content-center">
              <div class="row mt-2">
                  <div class="col-md-6">
                      <p-calendar  [showIcon]="true" inputId="icon" placeholder="Start Date"></p-calendar>
                  </div>
                  <div class="col-md-6">
                      <p-calendar  [showIcon]="true" inputId="icon" placeholder="End Date"></p-calendar>
                  </div>
                 
              </div>

          </div>
      </div>
      <div>
        <button type="button" class="btn btn-primary"
        ><i
            class="fas fa-search"></i>
        Search</button>
      </div> -->

    </div>

    <div class="row">
        <div class="col-lg-12">
            <h3 class="title-h3">Petfolio Partners</h3>
        </div>
    </div>
    <div class="row row-sm">

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body" (click)="dashboardCountNavigation('Customer_Management')">
                    <div class="card-item">
                        <div class="card-item-icon card-icon">
                            <img src="assets/images/004.png" alt
                                class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Customers</label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Registered
                                Customers</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">{{counts?.petloverdetails_count| number}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body" (click)="dashboardCountNavigation('Vendor_Management')">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-success">
                            <img src="assets/images/001.png" alt
                                class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Vendors</label>
                            <span class="d-block tx-12 mb-0 text-muted">
                                Registered Vendors</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">{{counts?.vendor_count | number}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body" (click)="dashboardCountNavigation('serviceprovider_details')">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-info">
                            <img src="assets/images/003.png" alt
                                class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Service PROVIDERS</label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Registered
                                Service Providers</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">{{counts?.sp_count | number}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body" (click)="dashboardCountNavigation('doctor/doctor_list')">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-danger">
                            <img src="assets/images/002.png" alt
                                class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Doctors</label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Registered
                                Doctors</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">{{counts?.doctordetails_count | number}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                         <!-- <div class="card-item-icon card-icon bg-danger">
                            <img src="assets/images/money-bag.png" alt
                                class="img-fluid">
                        </div> --> 
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Total
                                Revenue </label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Total
                                Revenue Amount</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">
                                    {{counts?.total_revenue | currency :'Rs. '}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <!-- <div class="card-item-icon card-icon bg-danger">
                            <img src="assets/images/money-bag.png" alt
                                class="img-fluid">
                        </div> -->
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Pet Care
                                Revenue </label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Pet Care
                                Revenue Amount</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">
                                    {{counts?.total_doctor_appointments_revenue | currency :'Rs. '}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <!-- <div class="card-item-icon card-icon bg-danger">
                            <img src="assets/images/money-bag.png" alt
                                class="img-fluid">
                        </div> -->
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Service 
                                Revenue </label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Service 
                                Revenue Amount</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">
                                    {{counts?.total_SP_appointments_revenue | currency :'Rs. '}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <!-- <div class="card-item-icon card-icon bg-danger">
                            <img src="assets/images/money-bag.png" alt
                                class="img-fluid">
                        </div> -->
                        <div class="card-item-title mb-2">
                            <label
                                class="main-content-label text-uppercase font-weight-bold mb-1">Product 
                                Revenue </label>
                            <span
                                class="d-block tx-12 mb-0 text-muted">Product 
                                Revenue Amount</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4
                                    class="font-weight-bold">
                                    {{counts?.total_product_order_revenue | currency :'Rs. '}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row  mt-3">
        <div class="col-lg-12">
            <h3 class="title-h3">Revenue</h3>
        </div>
    </div>
    <div class="row row-sm">

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-blue">
                            <img src="assets/images/006.png" alt="" class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label class="main-content-label text-uppercase font-weight-bold mb-1">Ecommerce</label>
                            <span class="d-block tx-12 mb-0 text-muted">Total Amount</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4 class="font-weight-bold">&#8377; 0</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-green">
                            <img src="assets/images/003.png" alt="" class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label class="main-content-label text-uppercase font-weight-bold mb-1">Service
                                Provider</label>
                            <span class="d-block tx-12 mb-0 text-muted">Appointment Booking</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4 class="font-weight-bold">&#8377; 0</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-org">
                            <img src="assets/images/004.png" alt="" class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label class="main-content-label text-uppercase font-weight-bold mb-1">Customer</label>
                            <span class="d-block tx-12 mb-0 text-muted">Subscription</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4 class="font-weight-bold">&#8377; 0</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="card-item-icon card-icon bg-secondary">
                            <img src="assets/images/002.png" alt="" class="img-fluid">
                        </div>
                        <div class="card-item-title mb-2">
                            <label class="main-content-label text-uppercase font-weight-bold mb-1">Doctors</label>
                            <span class="d-block tx-12 mb-0 text-muted">Total Amount</span>
                        </div>
                        <div class="card-item-body">
                            <div class="card-item-stat">
                                <h4 class="font-weight-bold">&#8377;{{Price_counts}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div> -->

</section>

<section class="pendinguser">

    <div class="row  mt-3 ">
        <div class="col-lg-12">
            <h3 class="title-h3">List of pending user registration approval
                by</h3>
        </div>
    </div>
    <div class="row row-sm">
        <div class="col-lg-4">

            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="card-item-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h4 class="head-text">Vendors</h4>

                                </div>
                            </div>
                            <p-table [value]="Vendor_list | filter:searchQR"
                                styleClass="ui-table-customers" #tt
                                [paginator]="true" [rows]="5" [rowHover]="true"
                                [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']">
                                <!-- <ng-template pTemplate="caption">
                                  <div fxLayout="row" fxLayoutAlign="space-around center">
                                      <div fxFlex="50">
                                          <h5 style="color:#fff;">Entry Type List</h5>
                                      </div>
                                      <div fxFlex="50">
                                          <div class="ui-table-globalfilter-container">
                                              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                              <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="Entry Type">
                                          </div>
                                      </div>

                                  </div>

                              </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th class="the">
                                            Business Name
                                        </th>
                                        <!-- <th>
                                          Business email
                                      </th>
                                      <th>
                                          Contact Number
                                      </th> -->
                                        <th>Business</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr class="tdrow">
                                        <td colspan="3">No Record Found</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item
                                    let-i="rowIndex">

                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <td
                                            appIsEllipsDirective>{{item.bussiness_name}}</td>
                                        <!-- <td>{{item.bussiness_email}} </td>
                                      <td> {{item.bussiness_phone}}</td> -->
                                        <td
                                            appIsEllipsDirective>{{item.bussiness}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-4">

            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="card-item-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h4 class="head-text">Service Providers</h4>

                                </div>
                            </div>
                            <p-table [value]="sp_list | filter:searchQR"
                                styleClass="ui-table-customers" #tt
                                [paginator]="true" [rows]="5" [rowHover]="true"
                                [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']">
                                <!-- <ng-template pTemplate="caption">
                                  <div fxLayout="row" fxLayoutAlign="space-around center">
                                      <div fxFlex="50">
                                          <h5 style="color:#fff;">Entry Type List</h5>
                                      </div>
                                      <div fxFlex="50">
                                          <div class="ui-table-globalfilter-container">
                                              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                              <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="Entry Type">
                                          </div>
                                      </div>

                                  </div>

                              </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <!-- <th style="width: 60px;">
                                          image
                                      </th> -->
                                        <th class="the" appIsEllipsDirective>
                                            Service Providers Name
                                        </th>
                                        <th>
                                            Services
                                        </th>
                                        <!-- <th>
                                          Created at
                                      </th> -->
                                        <!-- <th style="width:90px;text-align:center">
                                          Action
                                      </th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr class="tdrow">
                                        <td colspan="3">No Record Found</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item
                                    let-i="rowIndex">
                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <!-- <td>
                                          <div class="card-box-i">
                                              <img src="assets/images/005.png" alt="">
                                          </div>
                                      </td> -->
                                        <td
                                            appIsEllipsDirective>{{item.bus_user_name}}</td>
                                        <td appIsEllipsDirective>
                                            <ng-container
                                                *ngFor="let exp of item.bus_service_list">
                                                {{exp.bus_service_list}},
                                            </ng-container>
                                        </td>
                                        <!-- <td>17-09-2020</td> -->
                                        <!-- <td class="act">
                                          <i class="fa fa-check-circle-o" aria-hidden="true" style="color: rgb(2, 187, 57);"
                                              pTooltip="Approve"></i>
                                          <i class="fa fa-times-circle-o" aria-hidden="true" style="color: rgb(223, 26, 26);"
                                              pTooltip="Reject"></i>
                                      </td> -->
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-4">

            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="card-item-body">

                            <div class="row">
                                <div class="col-lg-12">
                                    <h4 class="head-text">Doctors </h4>

                                </div>
                            </div>
                            <p-table [value]="doctor_list | filter:searchQR"
                                styleClass="ui-table-customers" #tt
                                [paginator]="true" [rows]="5" [rowHover]="true"
                                [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']">
                                <!-- <ng-template pTemplate="caption">
                                  <div fxLayout="row" fxLayoutAlign="space-around center">
                                      <div fxFlex="50">
                                          <h5 style="color:#fff;">Entry Type List</h5>
                                      </div>
                                      <div fxFlex="50">
                                          <div class="ui-table-globalfilter-container">
                                              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                              <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="Entry Type">
                                          </div>
                                      </div>

                                  </div>

                              </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th style="width: 70px;">
                                            Image
                                        </th>
                                        <th>
                                            Doctors Name
                                        </th>
                                        <!-- <th>Specialization </th> -->
                                        <!-- <th>Pets handled</th> -->
                                        <!-- <th>
                                          Created at
                                      </th> -->
                                        <!-- <th style="width:90px;text-align:center">
                                          Action
                                      </th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr class="tdrow">
                                        <td colspan="3">No Record Found</td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item
                                    let-i="rowIndex">
                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <div class="imgthumcontainer"
                                                style="width: 30px;height: 30px;"><img
                                                    src="{{item?.clinic_pic[0]?.clinic_pic}}"
                                                    alt class="thumbSm">
                                            </div>

                                        </td>
                                        <td
                                            appIsEllipsDirective>{{item.dr_title}}.{{item.dr_name}}</td>

                                        <!-- <td><ng-container  *ngFor="let spec of item.specialization">
                                      {{spec.specialization}} ,
                                   </ng-container></td> -->
                                        <!-- <td class="truncate-cell"><ng-container  *ngFor="let pet_handle of item.pet_handled">
                                      {{pet_handle.pet_handled}} ,
                                   </ng-container></td> -->
                                        <!-- <td>17-09-2020</td> -->
                                        <!-- <td class="act">
                                          <i class="fa fa-check-circle-o" aria-hidden="true" style="color: rgb(2, 187, 57);"
                                              pTooltip="Approve"></i>
                                          <i class="fa fa-times-circle-o" aria-hidden="true" style="color: rgb(223, 26, 26);"
                                              pTooltip="Reject"></i>
                                      </td> -->
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>