<section style="text-transform:capitalize">
    <div class="page-header ">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Service Provider Details</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>

    </div>


    <!-- table -->

    <div class="row row-sm">

        <div class="col-lg-12">


            <div class="card custom-card">
                <div class="card-body">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="search-hero">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"><i
                                                class="fas fa-search"></i></span>
                                    </div>
                                    <input class="form-control" type="text" name="search2" [(ngModel)]="searchQR"
                                        autocomplete="off" placeholder="Search...">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="Start Date"
                                class="mr-3"></p-calendar>
                            <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="End Date">
                            </p-calendar>
                        </div>

                        <div class="col-md-3">
                            <button type="button" class="btn btn-primary" style="margin-left: 10px;"
                                (click)="filter_date()">Search</button>
                            <button type="button" class="btn btn-secondary" (click)="refersh()">Refresh</button>

                        </div>
                    </div>

                    <hr>
                    <div class="doc-table">

                        <p-table [value]="rows | filter:searchQR" styleClass="ui-table-customers" #tt [paginator]="true"
                            [rows]="10" [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [globalFilterFields]="['type']">

                            <ng-template pTemplate="header">
                                <tr class="thhead">
                                    <th class="th-1">S.No </th>
                                    <!-- <th> Lgoin N & N </th> -->
                                    <th> Service Business name </th>
                                    <th> Services user name</th>
                                    <th> Services user number</th>
                                    <th> Services </th>
                                    <th> Specialization </th>
                                    <!-- <th>Certificate</th>
                                    <th>Govt ID proof </th>
                                    <th>Photo ID proof</th> -->
                                    <!-- <th>Created by</th>
                                    <th>Modified by</th> -->
                                    <!-- <th style="width: 130px;">Created at</th>
                                    <th style="width: 130px;">Modified at</th> -->
                                    <!-- <th>Device type</th> -->
                                    <th>GST No </th>
                                    <th>PAN No </th>
                                    <th style="width: 130px;">Approve / Reject</th>
                                    <!-- <th>Live / Block</th> -->
                                    <th style="width: 140px;text-align:center">
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr class="tdrow" style="text-transform:none" >
                                    <td (click)="view_details(item)">{{i+1}}</td>
                                    <!-- <td>{{item.user_id.first_name}} {{item.user_id.last_name}} & {{item.user_id.user_phone}}</td> -->
                                    <td (click)="view_details(item)">{{item.bussiness_name}}</td>

                                    <td (click)="view_details(item)">{{item.bus_user_name}}</td>
                                    <td (click)="view_details(item)">{{item.bus_user_phone}}</td>

                                    <td class="truncate-cell" (click)="view_details(item)">
                                        <ng-container *ngFor="let exp of item.bus_service_list" class="">
                                            {{exp.bus_service_list}},
                                        </ng-container>

                                    </td>
                                    <td class="truncate-cell" (click)="view_details(item)">
                                        <ng-container *ngFor="let exp of item.bus_spec_list">
                                            {{exp.bus_spec_list}},
                                        </ng-container>
                                    </td>
                                    <!-- <td class="act">
                                        <ng-container *ngFor="let doc of item.bus_certif">
                                            <i (click)="goToLink1(doc.bus_certif)" class="fas fa-file-text"
                                                style="cursor:pointer;background-color: #fff;color: #fd2c3a;" aria-hidden="true"></i>
                                        </ng-container>
                                   </td>
                                    <td class="act">
                                        <i (click)="goToLink1(item.bus_proof)" class="fas fa-file-text"
                                        style="cursor:pointer;background-color: #fff;color: #fd2c3a;" aria-hidden="true"></i>

                                    </td>
                                    <td class="act">
                                        <i (click)="goToLink1(item.bus_profile)" class="fas fa-file-text"
                                        style="cursor:pointer;background-color: #fff;color: #fd2c3a;" aria-hidden="true"></i>

                                    </td> -->

                                    <!-- <td>
                                        {{item.createdAt}}
                                    </td>
                                    <td>
                                        {{item.updatedAt}}
                                    </td> -->

                                    <!-- <td>
                                        {{item.mobile_type}}
                                    </td> -->
                                    <td (click)="view_details(item)">{{item?.bussiness_gst?item?.bussiness_gst:'-'}}</td>
                                    <td (click)="view_details(item)">{{item?.bussiness_pan?item?.bussiness_pan:'-'}}</td>
                                    <td  class="act text-center">

                                        <button class="btn btn-success btn-sm" (click)="verify('Verified',item._id)"
                                            style="padding: 2px; line-height: 1.2;font-size: 12px !Important;"
                                            *ngIf="item.profile_verification_status != 'Verified'">Approve</button>
                                        <button class="btn btn-danger1 btn-sm" (click)="verify('Not verified',item._id)"
                                            style="padding: 2px; line-height: 1.2;font-size: 12px !Important;"
                                            *ngIf="item.profile_verification_status == 'Verified'">Reject</button>


                                        <!-- <i class="fa fa-check-circle-o" *ngIf="item.profile_verification_status == 'Not verified' "
                                            (click)="verify('Verified',item._id)" aria-hidden="true" style="color: rgb(2, 187, 57);"
                                            pTooltip="Approve"></i>
                                        <i class="fa fa-times-circle-o" *ngIf="item.profile_verification_status == 'Verified' "
                                            (click)="verify('Not verified',item._id)" aria-hidden="true" style="color: rgb(223, 26, 26);"
                                            pTooltip="Reject"></i> -->
                                    </td>

                                    <td class="act" >
                                        <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete"></i> -->
                                        <i style="color: #4CAF50;" class="fas fa-exclamation-circle" aria-hidden="true"
                                            (click)="view_details(item,$event)" pTooltip="View"></i>
                                        <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                            pTooltip="Delete" (click)="delete(item._id)"></i> -->
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer" >
                                <tr class="full text-center" *ngIf="isLoading" >
                                    <td colspan="9" >
                                      <div class="custom-flex">
                                      <img class="img-fluid loader-logo" src="assets/images/dogloader.gif" alt="loader" > <div class="fs">Loading....</div> </div>
                                    </td>   
                                  </tr>
                                <tr class="full text-center" *ngIf="rows.length==0 && !isLoading">
                                    <td colspan="9" >
                                      <div class="custom-flex">
                                                <img class="img-fluid loader-logo" src="assets/images/nodataimage.png" alt="Logo" >
                                               <div class="fs">No records found</div> </div>
                                    </td>
                                  </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" >
                                <tr  *ngIf="rows?.length !=0 && !isLoading">
                                    <td colspan="9"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                </tr>
                            </ng-template>
                        </p-table>


                    </div>

                </div>
            </div>
        </div>
    </div>

</section>