import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-add',
  templateUrl: './vendor-add.component.html',
  styleUrls: ['./vendor-add.component.css']
})
export class VendorAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
