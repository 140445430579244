<section style="text-transform:capitalize">
    <div class="page-header ">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Doctor Details </h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>

    </div>




    <!-- table -->

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <!-- <div class="card-item"> -->

                    <div class="row">
                        <div class="col-md-4">
                            <div class="search-hero">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <span class="clearable">
                                        <input class="form-control" type="text" name="search2" [(ngModel)]="searchQR"
                                            autocomplete="off" placeholder="Search..."
                                            (input)="tt.filterGlobal($event.target.value, 'contains')"
                                            (keypress)='research()' (blur)='research1()'>
                                        <i *ngIf="shremove" class="clearable__clear"
                                            (click)="tt.filterGlobal('', 'contains');remove()">&times;</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 text-center">
                            <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="Start Date"
                                [readonlyInput]="true" class="mr-3"></p-calendar>
                            <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="End Date"
                                [readonlyInput]="true" class="mr-3"></p-calendar>

                            <!-- </div>
                        <div class="col-md-3 text-center"> -->
                            <button type="button" class="btn btn-primary" (click)="filter_date()">
                                <i class="fas fa-search"></i> Search</button>
                            <button *ngIf="update_button == true" type="button" class="btn btn-secondary"
                                (click)="refersh()">
                                <i class="fas fa-sync-alt"></i> Refresh</button>
                            <button type="button" class="btn btn-primary mr-0" style="text-align:right;"
                                (click)="doc_form()">
                                <i class="fas fa-plus-circle"></i> Add</button>
                        </div>
                    </div>
                    <!-- <div class="row">
                            <div class="col-md-4">
                                <h5 class="form-lable">Specialization:</h5>
                                <p-dropdown [options]="specialzation_list" [(ngModel)]="specialzation_f" placeholder="Select"
                                    optionLabel="specialzation" [showClear]="true"></p-dropdown>
                            </div>
                            <div class="col-md-4">
                                <button *ngIf="update_button == true" type="button" class="btn btn-primary"
                                    style="margin-left: 10px;margin-top: 24px;" (click)="spec_filter()"><i class="fas fa-filter"></i> Filter</button>
                            </div>

                          </div> -->
                    <div class="doc-table table-responsive" >
                        <div class="ui-helper-clearfix" style="text-align: left">
                            <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="EXCEL"
                                (click)="exportAsXLSX()" style="margin-right: 0.5em; margin-bottom: 10px;"></button>
                            <!--  <button type="button" pButton icon="fas fa-file-o" iconPos="left" label="All Data" (click)="dt.exportCSV()" style="float:left"></button>          -->
                        </div>

                        <p-table [value]="rows" styleClass="ui-table-customers" #tt [paginator]="true" [rows]="15"
                            [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [globalFilterFields]="[
                            'dr_title','clinic_name','user_id.first_name','dr_name'
                            ]">
                            <!-- <ng-template pTemplate="caption">
                                <div fxLayout="row" fxLayoutAlign="space-around center">
                                    <div fxFlex="50">
                                        <h5 style="color:#fff;">Entry Type List</h5>
                                    </div>
                                    <div fxFlex="50">
                                        <div class="ui-table-globalfilter-container">
                                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                            <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Entry Type">
                                        </div>
                                    </div>

                                </div>

                            </ng-template> -->
                            <ng-template pTemplate="header">
                                <tr class="thhead">
                                    <th class="th-1">
                                        S.No
                                    </th>
                                    <th>
                                        Image
                                    </th>
                                    <th >
                                        Name
                                    </th>
                                    <th >
                                        Clinic Name
                                    </th>
                                    <th style="width: 190px;" >
                                        login Name & No
                                    </th>
                                    <th style="width: 210px;" >
                                        Education / Year Of Completion
                                    </th>
                                    <th>Experience </th>
                                    <th>Specialization </th>

                                    <th>Clinic Location</th>

                                    <th> Profile Status</th>
                                    <!-- <th>Device Type</th> -->
                                    <th>Consultancy Fees</th>
                                    <!-- <th>Profile Stauts</th> -->
                                    <!-- <th>  Business GST </th>
                                    <th>  Business PAN </th> -->
                                    <th >Live Status
                                    </th>
                                   

                                    <th style="text-align:center">
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr class="tdrow" style="text-transform:none; " >
                                    <td (click)="view_details(item)" pTooltip="{{i+1}}">{{i+1}}</td>
                                    <td (click)="view_details(item)">
                                        <div class="imgthumcontainer" *ngIf="item.clinic_pic.length != 0">
                                            <span><img _ngcontent-dah-c159="" src="{{item.clinic_pic[0].clinic_pic}}"
                                                    alt="" style="width: 100%;"></span>
                                        </div>

                                    </td>
                                    <td (click)="view_details(item)" pTooltip="{{item.dr_title}}. {{item.dr_name}}">{{item.dr_title }}. {{item.user_id?.first_name}} {{item.user_id?.last_name}}
                                    </td>
                                    <td (click)="view_details(item)" pTooltip="{{item.clinic_name}}">{{item.clinic_name}}</td>

                                    <td (click)="view_details(item)" pTooltip="{{item.user_id?.first_name}} {{item.user_id?.last_name}} &
                                    {{item.user_id?.user_phone}}"> {{item.user_id?.first_name}} {{item.user_id?.last_name}}
                                        &
                                        {{item.user_id?.user_phone}}</td>

                                    <td (click)="view_details(item)">
                                        <ng-container *ngFor="let edu of item.education_details">
                                            <span pTooltip=" {{edu.education}}-{{edu.year}}">
                                                {{edu.education}}-{{edu.year}} , </span>
                                        </ng-container>

                                    </td>
                                    <td (click)="view_details(item)" class="truncate-cell">
                                        <ng-container *ngFor="let exp of item.experience_details">
                                            <span pTooltip="{{exp.company}} ({{exp.from}}-{{exp.to}})">{{exp.company}}
                                                ({{exp.from}}-{{exp.to}}) ,</span>

                                        </ng-container>

                                    </td>
                                    <td (click)="view_details(item)" class="truncate-cell">
                                        <ng-container *ngFor="let spec of item.specialization">
                                            <span pTooltip="{{spec.specialization}}">{{spec.specialization}} ,</span>
                                        </ng-container>
                                    </td>

                                    <td (click)="view_details(item)" pTooltip="{{item.clinic_loc}}" class="truncate-cell">{{item.clinic_loc}}</td>
                                    <td (click)="view_details(item)" pTooltip="{{item.profile_status}}" style=" text-transform: capitalize;">
                                        {{item.profile_status}}
                                    </td>
                                    <!-- <td pTooltip="{{item.user_id.mobile_type}}">{{item.user_id.mobile_type}}</td> -->
                                    <td (click)="view_details(item)" pTooltip="{{item.consultancy_fees}}" style="text-align: right;">
                                        {{item.consultancy_fees}}</td>
                                        <!-- <td (click)="view_details(item)">{{item?.bussiness_gst?item?.bussiness_gst:'-'}}</td>
                                        <td (click)="view_details(item)">{{item?.bussiness_pan?item?.bussiness_pan:'-'}}</td> -->
                                    <td >
                                        <button class="btn btn-success btn-sm"
                                            (click)="verify('Verified',item._id,item)" pTooltip="Approve"
                                            style="padding: 5px; line-height: 1.2;font-size: 12px !Important;"
                                            *ngIf="item.profile_verification_status != 'Verified'"><i
                                                class="fas fa-user-check"></i><span
                                                class="pr-1 pl-1">Approve</span></button>
                                        <button class="btn  btn-danger1 btn-sm"
                                            (click)="verify1('Not verified',item._id,item)" pTooltip="Reject"
                                            style="padding: 5px; line-height: 1.2;font-size: 12px !Important;"
                                            *ngIf="item.profile_verification_status == 'Verified'"><i
                                                class="fas fa-user-slash"></i><span>Reject</span></button>
                                    </td>
                                   
                                    <td class="act text-center">
                                        <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true"
                                            (click)="edit(item)" pTooltip="Edit"></i>
                                        <i style="color: #4CAF50;" class="fas fa-exclamation-circle" aria-hidden="true"
                                            (click)="view_details(item)" pTooltip="View"></i>
                                            <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete" (click)="Deletecompanydetails(item.user_id._id)"></i> -->
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer" >
                                <tr class="full text-center" *ngIf="isLoading" >
                                    <td colspan="10" >
                                      <div class="custom-flex">
                                      <img class="img-fluid loader-logo" src="assets/images/dogloader.gif" alt="loader" > <div class="fs">Loading....</div> </div>
                                    </td>   
                                  </tr>
                                <tr class="full text-center" *ngIf="rows.length==0 && !isLoading">
                                    <td colspan="10" >
                                      <div class="custom-flex">
                                                <img class="img-fluid loader-logo" src="assets/images/nodataimage.png" alt="Logo" >
                                               <div class="fs">No records found</div> </div>
                                    </td>
                                  </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" >
                                <tr  *ngIf="rows?.length !=0 && !isLoading">
                                    <td colspan="10"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>

                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>

</section>