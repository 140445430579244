<section *ngIf="dropdownslist != undefined">
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1" *ngIf="type == 'create'">Create Doctor </h2>
            <h2 class="main-content-title tx-24 mb-1" *ngIf="type == 'edit'">Edit Doctor Details</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>

        <div class="d-flex">
            <!-- <button class="btn btn-sm btn-secondary " (click)="back()"> <i class="fas fa-arrow-left ml-1"
                    aria-hidden="true"></i> Back</button> -->
        </div>
    </div>





    <div class="card-box">
        <div class="row" style="padding: 0px 0px 23px 10px;">
            <div class="row" *ngIf="stage1">
                <div class="col-md-6 mb-3">
                    <h5 class="form-lable required">First Name :</h5>
                    <input type="text"  pInputText [(ngModel)]="tittle" placeholder="First Name"
                        (keydown)="keydown($event)">
                    <div *ngIf="Validation == false && (tittle == undefined || tittle.trim() == '') "
                        class="mt-1 error">
                        <div class="text-danger">First Name is Required.</div>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <h5 class="form-lable required">Last Name:</h5>
                    <input type="text" pInputText [(ngModel)]="Name" placeholder="Last Name"
                        (keydown)="keydown($event)">
                    <div *ngIf="Validation == false && (Name == undefined || Name.trim() == '' )" class="mt-1 error">
                        <div class="text-danger">Last Nameis Required.</div>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <h5 class="form-lable required">Email ID:</h5>
                    <input type="text" pInputText [(ngModel)]="Email" (ngModelChange)="EmailidChange($event)"
                        [disabled]="type == 'edit'" placeholder="Email ID">
                    <div *ngIf="Email_idError" class="mt-1 error">
                        <div class="text-danger">Valid Email Address Required.</div>
                    </div>
                    <div *ngIf="Validation == false && Email_id == undefined" class="mt-1 error">
                        <div class="text-danger">Valid Email Address Required.</div>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <h5 class="form-lable required">Phone Number:</h5>
                    <input type="text" pInputText [(ngModel)]="Phone" placeholder="Phone Number" maxlength="10"
                        [disabled]="type == 'edit'" (keypress)=_keyPress($event)>
                    <div *ngIf="Validation == false && (Phone == undefined || Phone == ''  || Phone.length != 10)"
                        class="mt-1 error">
                        <div class="text-danger">Phone Number is Required.</div>
                    </div>
                </div>
                <div class="col-md-12 mt-20" *ngIf="!stage2">
                    <button type="button" class="btn btn-primary" (click)="userFormSubmit()">Continue</button>
                    <!-- <button type="button" class=" btn btn-danger" (click)="cancel()">Cancel</button> -->
                </div>
            </div>


            <div  *ngIf="stage2">
                <ng-container>
                    
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 15px;">
                                <h4 class="title">Doctor Business Info</h4>
                            </div>
                            <div class="col-md-5 mt-3">
                                <h5 class="form-lable">Business Email : <span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="business_email" (ngModelChange)="EmailidChange($event)"
                                 placeholder="Business Email" >
                                 <div *ngIf="Email_idError" class="mt-1 error">
                                    <div class="text-danger">Valid Email Address Required.</div>
                                </div>
                                <div *ngIf="Validation2 == false && (business_email == undefined || business_email.trim() == '') "
                                    class="mt-1 error">
                                    <div class="text-danger">Business Email is Required.</div>
                                </div>
                            </div>
                            <div class="col-md-5 mt-3">
                                <h5 class="form-lable">Clinic Number : <span
                                        style="color: red;font-size: large;">*</span></h5>
                                <input type="text" autofocus="autofocus" pInputText (keypress)=_keyPress($event)
                                    maxlength="10" minlength="10" [(ngModel)]="clinic_number"
                                    placeholder="Clinic Number" required>
                                <div *ngIf="Validation2 == false && (clinic_number == undefined || clinic_number.trim() == '') "
                                    class="mt-1 error">
                                    <div class="text-danger">Clinic Number is Required.</div>
                                </div>
                                <div *ngIf="Validation2 == false && ( clinic_number =='0000000000') "
                                    class="mt-1 error">
                                    <div class="text-danger">Vaild Clinic Number is Required.</div>
                                </div>
                            </div>
                            <div class="col-md-5 mt-3">
                                <h5 class="form-lable">Doctor ID : <span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="doctor_id" placeholder="Doctor ID">
                                <div *ngIf="Validation2 == false && (doctor_id == undefined || doctor_id.trim() == '') "
                                    class="mt-1 error">
                                    <div class="text-danger">Doctor ID is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-5 mt-3">
                                <h5 class="form-lable">Clinic Name : <span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText maxlength="25" [(ngModel)]="Clinic_Name"
                                    placeholder="Clinic Name" (keydown)="keydown($event)">
                                <div *ngIf="Validation2 == false && (Clinic_Name == undefined || Clinic_Name.trim() == '') "
                                    class="mt-1 error">
                                    <div class="text-danger">Clinic Name is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-5 mt-3">
                                <h5 class="form-lable">Select Communication Type : <span
                                        style="color: red;font-size: large;">*</span></h5>
                                <select name="cars" id="cars" [(ngModel)]="communication_type">
                                    <option value="" selected disabled>Select Type</option>
                                    <option *ngFor="let item of dropdownslist.communication_type; let i= index;" [value]="item.com_type">
                                        {{item.com_type}}
                                    </option>
                                </select>
                                <div *ngIf="Validation2 == false && (communication_type == undefined || communication_type.trim() == '') "
                                    class="mt-1 error">
                                    <div class="text-danger">Communication Type is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-10 mt-3">
                                <h5 class="form-lable">About Doctor : <span
                                        style="color: red;font-size: large;">*</span></h5>
                                <textarea type="text" pInputText [(ngModel)]="about_doctor"
                                    placeholder="About Doctor"></textarea>
                                <div *ngIf="Validation2 == false && (about_doctor == undefined || about_doctor.trim() == '') "
                                    class="mt-1 error">
                                    <div class="text-danger">About Doctor is Required.</div>
                                </div>
                            </div>





                        </div>
                   

                    <hr>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-5">
                                <h5 class="form-lable">Education: <span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="Education" placeholder="Education">
                                <div *ngIf="Validation2 == false && (Completionarray.length == 0) " class="mt-1 error">
                                    <div class="text-danger">Education is Required.</div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <h5 class="form-lable">Year Of Completion:</h5>

                                <select [(ngModel)]="completion">
                                    <option value='undefined' disabled>Select Year</option>
                                    <option *ngFor="let arr of year_list ; let i= index;">
                                        {{arr}}
                                    </option>
                                </select>
                                <div *ngIf="Validation2 == false && (Completionarray.length == 0) " class="mt-1 error">
                                    <div class="text-danger">Year Of Completion is Required.</div>
                                </div>
                                <!-- <input type="" pInputText [(ngModel)]="completion" placeholder="Completion"> -->
                            </div>
                            <div class="col-md-2 text-right">
                                <i class="fa fa-plus add-icon" (click)="addcompletion()" aria-hidden="true"
                                    pTooltip="Add"></i>
                            </div>
                        </div>
                        <table *ngIf="Completionarray.length > 0 " class="spec-table">
                            <thead>
                                <th>S.No</th>
                                <th>Education</th>
                                <th>Year Of Completion</th>
                                <th>Remove</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let arr of Completionarray ; let i= index;">
                                    <td>{{i+1}}</td>
                                    <td>{{arr.education}}</td>
                                    <td>{{arr.year}}</td>
                                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                            pTooltip="Delete" (click)="remove_completion(i)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <hr>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="form-lable" style="font-weight: 600;">Experience <span
                                        style="color: red;font-size: large;">*</span></h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="form-lable">Company:</h5>
                                <input type="text" pInputText [(ngModel)]="CName" placeholder="Company Name">
                            </div>
                            <div class="col-md-3">
                                <h5 class="form-lable">From:</h5>
                                <select [(ngModel)]="f_date">
                                    <option value='undefined' disabled>Select Year</option>
                                    <option *ngFor="let arr of year_list ; let i= index;">
                                        {{arr}}
                                    </option>
                                </select>


                                <!-- <p-calendar [(ngModel)]="f_date" [showIcon]="true" inputId="icon"></p-calendar> -->
                            </div>
                            <div class="col-md-3">
                                <h5 class="form-lable">To:</h5>
                                <select [(ngModel)]="T_date">
                                    <option value='undefined' disabled>Select Year</option>
                                    <option *ngFor="let arr of year_list ; let i= index;">
                                        {{arr}}
                                    </option>
                                </select>
                                <div *ngIf="Validation2 == false && (
                                    Experiencearray.length == 0) " class="mt-1 error">
                                    <div class="text-danger">Please Fill All The Fields</div>
                                </div>

                                <!-- <p-calendar [(ngModel)]="T_date" [showIcon]="true" inputId="icon"></p-calendar> -->
                            </div>
                            <div class="col-md-2 text-right">
                                <i class="fa fa-plus add-icon" (click)="addExperience()" aria-hidden="true"
                                    pTooltip="Add"></i>
                            </div>


                        </div>
                        <table *ngIf="Experiencearray.length > 0 " class="spec-table">
                            <thead>
                                <th>S.No</th>
                                <th>Company</th>
                                <th>From Date</th>
                                <th>To Date</th>
                                <th>Exp</th>
                                <th>Remove</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let arr of Experiencearray ; let i= index;">
                                    <td>{{i+1}}</td>
                                    <td>{{arr.company}}</td>
                                    <td>{{arr.from}}</td>
                                    <td>{{arr.to}}</td>
                                    <td>{{arr.yearsofexperience}}</td>
                                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                            pTooltip="Delete" (click)="remove_Experience(i)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <hr>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 class="form-lable">Specialization : <span
                                        style="color: red;font-size: large;">*</span></h5>
                                <select [(ngModel)]="Specialization">
                                    <option value='undefined' style="display:none;">Select Specialization</option>
                                    <option *ngFor="let arr of dropdownslist.specialzation ; let i= index;">
                                        {{arr.specialzation}}
                                    </option>
                                </select>
                                <div *ngIf="Validation2 == false && (Specializationarray.length == 0) "
                                    class="mt-1 error">
                                    <div class="text-danger">Specialization is Required.</div>
                                </div>
                                <!-- <input type="text" pInputText [(ngModel)]="Specialization" placeholder="Specialization"> -->
                            </div>
                            <div class="col-md-2 text-right">
                                <i class="fa fa-plus add-icon" (click)="addSpecialization()" aria-hidden="true"
                                    pTooltip="Add"></i>
                            </div>


                        </div>
                        <table *ngIf="Specializationarray.length > 0 " class="spec-table">
                            <thead>
                                <th>S.No</th>
                                <th>Specialization</th>
                                <th>Remove</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let arr of Specializationarray ; let i= index;">
                                    <td>{{i+1}}</td>
                                    <td>{{arr.specialization}}</td>
                                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                            pTooltip="Delete" (click)="remove_Specialization(i)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <hr>

                    <div class="row col-md-12">
                        <div class="col-md-7">
                            <h5 class="form-lable">Clinic Address : <span style="color: red;font-size: large;">*</span>
                            </h5>
                            <input [(ngModel)]="address" style="padding:10px; width:75%" 
                                 placeholder="Enter Clinic Location" [options]='options'  ngx-google-places-autocomplete  (onAddressChange)="handleAddressChange($event)" #placesRef="ngx-places" 
                                />
                                <!-- <textarea name="" pInputText rows="6" placeholder="Enter Your Full Address" [(ngModel)]="address" id=""></textarea> -->
                                <div *ngIf="Validation2 == false && (address == undefined || address.trim() == '') "
                                class="mt-1 error">
                                <div class="text-danger">Clinic Location is Required.</div>
                            </div>
                            <!-- 
                          -->
                        </div>
                        <div class="col-md-5">
                            <h5 class="form-lable">City Name : <span style="color: red;font-size: large;">*</span></h5>
                            <input [(ngModel)]="city_name" placeholder="Enter a City Name"
                                style="padding:10px; width:75%" />
                            <div *ngIf="Validation2 == false && (city_name == undefined || city_name.trim() == '') "
                                class="mt-1 error">
                                <div class="text-danger">City Name is Required.</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <div class="map">
                            <agm-map [latitude]="base_lat" [longitude]="base_lng" [zoom]="zoom"
                                [disableDefaultUI]="false" [zoomControl]="false">

                                <agm-marker [latitude]="location_lat" [longitude]="location_lng"
                                    [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">

                                    <agm-info-window>
                                        <strong>{{address}}</strong>
                                    </agm-info-window>
                                </agm-marker>
                            </agm-map>
                        </div>
                    </div>

                    <hr>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6" style="margin-bottom: 15px;">
                                <h5 class="form-lable required">Clinic Image :</h5>
                                <input type="file" #imgType (change)="fileupload($event,'Clinic')" id="imgfile"
                                    accept=".png, .jpg, .jpeg, .pdf" class=" hei form-control" id="city"
                                    placeholder="User type icon">

                                <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
                                    Image(jpeg, png, jpg) max 2 mb </p>
                                <div *ngIf="Validation2 == false && clinic_arr.length == 0 " class="mt-1 error">
                                    <div class="text-danger">Clinic Image is Required.</div>
                                </div>
                                <!-- <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with 100 * 100 (Max 200 kB)</div> -->
                                <!-- <img *ngIf="img_path != undefined" src="{{img_path}}" style="width: 20px;"> -->
                                <!-- <i class="fa fa-plus add-icon" (click)="addhandled()" aria-hidden="true" pTooltip="Add"></i> -->
                                <table *ngIf="clinic_arr.length > 0 " class="spec-table" style="margin-top: 8px;">

                                    <thead>
                                        <th>S.No</th>
                                        <th>Clinic Image</th>
                                        <th>Remove</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let arr of clinic_arr ; let i= index;">
                                            <td>{{i+1}}</td>
                                            <td><img src="{{arr.clinic_pic}}" style="width: 20px;"></td>

                                            <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                    pTooltip="Delete" (click)="remove_clinic_img(i)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6" style="margin-bottom: 15px;">
                                <h5 class="form-lable required">Clinic Logo :</h5>
                                <input type="file" #imgType (change)="fileupload($event,'clinic_logo')" id="imgfile"
                                    accept=".png, .jpg, .jpeg, .pdf" class="hei form-control" id="city"
                                    placeholder="User type icon">
                                <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only Image(jpeg,
                                    png, jpg) 200 X 200</p>
                                <div *ngIf="Validation2 == false && ( clinic_logo == '') " class="mt-1 error">
                                    <div class="text-danger">Clinic logo is Required.</div>
                                </div>

                                <!-- <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with 100 * 100 (Max 200 kB)</div> -->


                                <!-- <i class="fa fa-plus add-icon" (click)="addhandled()" aria-hidden="true" pTooltip="Add"></i> -->
                                <table *ngIf="clinic_logo != ''" class="spec-table" style="margin-top: 8px;">

                                    <thead>
                                        
                                        <th>Clinic Logo </th>
                                        <th>Remove</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            
                                            <td><img src="{{clinic_logo}}" style="width: 20px;"></td>

                                            <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                    pTooltip="Delete" (click)=remove_clinic_logo()></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6" style="margin-bottom: 15px;">
                                <h5 class="form-lable required">Thumb Image:</h5>
                                <input type="file" #imgType (change)="fileupload($event,'thumbnail_img')" id="imgfile"
                                    accept=".png, .jpg, .jpeg, .pdf" class="hei form-control" id="city"
                                    placeholder="User type icon">
                                <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only Image(jpeg,
                                    png, jpg) 200 X 200</p>
                                <div *ngIf="Validation2 == false && ( thumbnail_image== undefined) " class="mt-1 error">
                                    <div class="text-danger">Thumb Image is Required.</div>
                                </div>

                                <!-- <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with 100 * 100 (Max 200 kB)</div> -->


                                <!-- <i class="fa fa-plus add-icon" (click)="addhandled()" aria-hidden="true" pTooltip="Add"></i> -->
                                <table *ngIf="thumbnail_image != undefined" class="spec-table" style="margin-top: 8px;">

                                    <thead>
                                        <th>S.No</th>
                                        <th>Thumb Image</th>
                                        <th>Remove</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{"1"}}</td>
                                            <td><img src="{{thumbnail_image}}" style="width: 20px;"></td>

                                            <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                    pTooltip="Delete" (click)=remove_thumb_img()></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-md-6" style="margin-bottom: 15px;">
                                <h5 class="form-lable required">Govt ID proof:</h5>
                                <input type="file" #imgType (change)="fileupload($event,'Govt')" id="imgfile"
                                    accept=".png, .jpg, .jpeg, .pdf" class="hei form-control" id="city"
                                    placeholder="User type icon">
                                <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
                                    Image(jpeg, png, jpg) max 2 mb </p>
                                <div *ngIf="Validation2 == false && govt_arr.length == 0 " class="mt-1 error">
                                    <div class="text-danger">Govt ID proof is Required.</div>
                                </div>
                                <!-- <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with 100 * 100 (Max 200 kB)</div> -->
                                <!-- <img *ngIf="img_path != undefined" src="{{img_path}}" style="width: 20px;"> -->

                                <!-- <i class="fa fa-plus add-icon" (click)="addhandled()" aria-hidden="true" pTooltip="Add"></i> -->
                                <table *ngIf="govt_arr.length > 0 " class="spec-table" style="margin-top: 8px;">
                                    <thead>
                                        <th>S.No</th>
                                        <th>Govt ID proof</th>
                                        <th>Remove</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let arr of govt_arr ; let i= index;">
                                            <td>{{i+1}}</td>
                                            <td><img src="{{arr.govt_id_pic}}" style="width: 20px;"></td>

                                            <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                    pTooltip="Delete" (click)="remove_govt_img(i)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <h5 class="form-lable required">Photo ID proof:</h5>
                                <input type="file" #imgType (change)="fileupload($event,'Photo')" id="imgfile"
                                    accept=".png, .jpg, .jpeg, .pdf" class=" hei form-control" id="city"
                                    placeholder="User type icon">
                                <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
                                    Image(jpeg, png, jpg) max 2 mb </p>
                                <div *ngIf="Validation2 == false && photo_arr.length  == 0 " class="mt-1 error">
                                    <div class="text-danger">Photo ID proof is Required.</div>
                                </div>
                                <table *ngIf="photo_arr.length > 0 " class="spec-table" style="margin-top: 8px;">
                                    <thead>
                                        <th>S.No</th>
                                        <th>Photo ID proof</th>
                                        <th>Remove</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let arr of photo_arr ; let i= index;">
                                            <td>{{i+1}}</td>
                                            <td><img src="{{arr.photo_id_pic}}" style="width: 20px;"></td>
                                            <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                    pTooltip="Delete" (click)="remove_photo_img(i)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <h5 class="form-lable required">Certificate:</h5>
                                <input type="file" #imgType (change)="fileupload($event, 'Certificate')" id="imgfile"
                                    accept=".png, .jpg, .jpeg, .pdf" class=" hei form-control" id="city"
                                    placeholder="User type icon">
                                <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
                                    Image(jpeg, png, jpg) max 2 mb </p>
                                <div *ngIf="Validation2 == false && certificate_arr.length == 0 " class="mt-1 error">
                                    <div class="text-danger">Certificate is Required.</div>
                                </div>
                                <!-- <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with 100 * 100 (Max 200 kB)</div> -->
                                <!-- <img *ngIf="img_path != undefined" src="{{img_path}}" style="width: 20px;"> -->
                                <!-- <i class="fa fa-plus add-icon" (click)="addhandled()" aria-hidden="true" pTooltip="Add"></i> -->
                                <table *ngIf="certificate_arr.length > 0 " class="spec-table" style="margin-top: 8px;">
                                    <thead>
                                        <th>S.No</th>
                                        <th>Certificate</th>
                                        <th>Remove</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let arr of certificate_arr ; let i= index;">
                                            <td>{{i+1}}</td>
                                            <td><img src="{{arr.certificate_pic}}" style="width: 20px;"></td>
                                            <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                    pTooltip="Delete" (click)="remove_Certificate_img(i)"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="col-md-6 mb-2">
                                <h5 class="form-lable">consultancy Fee : <span
                                        style="color: red;font-size: large;">*</span></h5>
                                <input type="text" pInputText (keypress)=_keyPress($event)
                                    [(ngModel)]="consultancy_fees" placeholder="consultancy Fee">
                            </div>
                            <div class="col-md-6 mb-2">
                                <h5 class="form-lable">Business GST : 
                                    <!-- <span
                                        style="color: red;font-size: large;">*</span> -->
                                    </h5>
                                <input type="text" pInputText (keypress)=_keyPress($event)
                                    [(ngModel)]="doctor_gst" placeholder="Business GST">
                            </div>
                            <div class="col-md-6">
                                <h5 class="form-lable">Business PAN : 
                                    <!-- <span
                                        style="color: red;font-size: large;">*</span> -->
                                    </h5>
                                <input type="text" pInputText 
                                    [(ngModel)]="doctor_pan" placeholder="Business PAN">
                            </div>
                            <div class="col-md-6 mt-4">
                                <h5 class="form-lable">Doctor Signature : <span
                                    style="color: red;font-size: large;">*</span></h5>
                                <div class="signature-container">
                                    <signature-pad class="signature-pad" [label]="'label'" [signaturePadOptions]="signaturePadOptions" #signaturePad></signature-pad>
                                  </div>
                                  <div *ngIf="Validation2 == false && this.signature == '' " class="mt-1 error">
                                    <div class="text-danger">Signature is Required.</div>
                                </div>
                                <button (click)="clear()" class="btn btn-secondary">Clear</button>
                                <button (click)="save()" class="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-20">
                     
                        <button type="button" *ngIf="type == 'create'" class="btn btn-primary"
                            (click)="create_1()">Create</button>
                        <button type="button" *ngIf="type == 'edit'" class="btn btn-primary"
                            (click)="edit()">Update</button>
                    </div>
                </ng-container>
            </div>




        </div>
    </div>
</section>