<section style="text-transform:capitalize">
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Sub Diagnosis</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>

    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-6">
                                <h5 class="form-lable">Diagnosis:</h5>
                                <p-dropdown [options]="rows1" [(ngModel)]="Diagnosis" (ngModelChange)="saverange()"
                                    placeholder="Select Diagnosis" optionLabel="diagnosis" [showClear]="true">
                                </p-dropdown>

                            </div>
                            <div class="col-md-6">
                                <h5 class="form-lable">Sub Diagnosis:</h5>
                                <input type="text" pInputText [(ngModel)]="Sub_Diagnosis" name="pet_breed"
                                    placeholder="Enter Sub Diagnosis">
                            </div>
                            <!-- <div class="col-md-3">
                      <h5 class="form-lable">Pet Breed Icon:</h5>
                      <input type="file" pInputText [(ngModel)]="value1" placeholder="Enter Pet Breed" >
                  </div> -->
                            <div class="col-md-4">
                                <button *ngIf="update_button == true" type="button" class="btn btn-primary mt-4"
                                    (click)="Insert_Sub_Diagnosis_details()"><i class="fas fa-plus-circle"></i>
                                    Add</button>
                                <button *ngIf="update_button == false" type="button" class="btn btn-primary mt-4"
                                    (click)="Edit_Sub_Diagnosis_details()"><i class="fas fa-edit"></i> Update</button>
                                <button *ngIf="update_button == false" type="button" class="btn btn-danger mt-4"
                                    (click)="cancel()">
                                    Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="row">
                            <div class="col-md-4">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <span class="clearable">
                                            <input class="form-control" type="text" name="search2"
                                                (input)="td.filterGlobal($event.target.value, 'contains')"
                                                [(ngModel)]="searchQR" autocomplete="off" placeholder="Search..."
                                                (keypress)='research()' (blur)='research1()'>
                                            <i *ngIf="shremove" class="clearable__clear"
                                                (click)="td.filterGlobal('', 'contains');remove()">&times;</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-center">
                                <!-- <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon"
                                    placeholder="Start Date" class="mr-3" [readonlyInput]="true"></p-calendar>
                                <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="End Date"
                                    class="mr-3" [readonlyInput]="true"></p-calendar>
                                <button type="button" class="btn btn-primary" (click)="filter_date()"><i
                                        class="fas fa-search"></i> Search</button> -->
                                <!-- <button type="button" class="btn btn-secondary float-right" (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i> Refresh</button> -->
                            </div>
                            <!-- <div class="col-md-3 text-right">
                                <button type="button" class="btn btn-secondary" (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i> Refresh</button>
                            </div> -->
                        </div>

                        <!-- search -->


                        <div class="table-responsive">
                            <p-table [value]="final" styleClass="ui-table-customers" #td [paginator]="true" [rows]="15"
                                [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['diagnosis_id.diagnosis','sub_diagnosis']">
                                <!-- <ng-template pTemplate="caption">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="50">
                <h5 style="color:#fff;">Entry Type List</h5>
            </div>
            <div fxFlex="50">
                <div class="ui-table-globalfilter-container">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Entry Type">
                </div>
            </div>

        </div>

    </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th>
                                            Diagnosis
                                        </th>
                                        <th>
                                            Sub Diagnosis
                                        </th>

                                        <th>Created by</th>
                                        <!-- <th>Created at</th> -->
                                        <th style="width: 163px;text-align:center">
                                            Action
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item let-i="rowIndex">
                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <td>{{item.diagnosis_id.diagnosis}}</td>
                                        <td>{{item.sub_diagnosis}}</td>
                                        <td>
                                            Admin
                                        </td>

                                        <!-- <td>
                                            {{item.createdAt |  date :'dd-MM-yyyy'}}
                                        </td> -->
                                        <td class="act text-center">
                                            <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true"
                                                (click)="Editcompanydetailsdata(item)" pTooltip="Edit"></i>
                                            <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                pTooltip="Delete" (click)="Deletecompanydetails(item._id)"></i>
                                            <!-- <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true"  pTooltip="View"></i> -->
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" >
                                    <tr class="full text-center" *ngIf="isLoading" >
                                        <td colspan="5" >
                                          <div class="custom-flex">
                                          <img class="img-fluid loader-logo" src="assets/images/dogloader.gif" alt="loader" > <div class="fs">Loading....</div> </div>
                                        </td>   
                                      </tr>
                                    <tr class="full text-center" *ngIf="final?.length==0 && !isLoading">
                                        <td colspan="5" >
                                          <div class="custom-flex">
                                                <img class="img-fluid loader-logo" src="assets/images/nodataimage.png" alt="Logo" >
                                               <div class="fs">No records found</div> </div>
                                        </td>
                                      </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" >
                                    <tr  *ngIf="final?.length !=0 && !isLoading">
                                        <td colspan="5"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</section>