<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="">
    <div class="row justify-content-center h-100 align-items-center">
        <div class="login_box">
            <div class="login_box_inner">

                <div class="container_login_form">
                    <!-- <div class="logo">
        <img
          src="../../../assets/img/brand/logo2.png"
          alt="">
      </div> -->
                    <div class="login_form">
                        <div _ngcontent-c5="" class="col-lg-12 grid-margin">
                            <div _ngcontent-c5="" class="card">

                                <div _ngcontent-c5="" class="card-body text-center" style="background:#ffffff">
                                    <div class="logo text-center mb-2">
                                        <img src="assets/images/logo/logo_pet12.png" alt="">
                                    </div>
                                    <!-- <h4 _ngcontent-c5="" class="card-title"
                                        style="font-size: 38px;font-weight: 600;text-align: center; color: #2295C4;padding: 0px;">
                                        Pet App</h4> -->
                                    <div>
                                        <div class="text-danger"></div>
                                    </div>
                                    <div *ngIf="loginError" class="alert alert-danger alert-dismissible fade show"
                                        role="alert">
                                        {{loginErrorMsg}}
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form class="my-form forms-sample ng-untouched ng-pristine ng-valid">
                                        <div class="form-group">
                                            <label><strong>Email Address</strong></label>
                                            <input [(ngModel)]="email_id" class="form-control"
                                                style="height:30px; font-size:12px;" placeholder="Email Address"
                                                type="email" name="email_id" (ngModelChange)="emailChange($event)"
                                                (focus)="focusUser()" [ngModelOptions]="{standalone: true}" #user>
                                            <div *ngIf="emailError">
                                                <div class="text-danger">{{emailErrorMsg}}</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label><strong>Password</strong></label>
                                            <!-- <span class="input-placeholder">
                                                <input class="form-control" placeholder="Password" type="password"
                                                    [type]="showPassword ? 'text' : 'password'"
                                                    style="height:30px; font-size:12px;" [(ngModel)]="passwords"
                                                    name="passwords" (ngModelChange)="passwordChange($event)"
                                                    (focus)="focusPassword()" [ngModelOptions]="{standalone: true}"
                                                    [type]="hide ? 'password' : 'text'">
                                                
                                            </span> -->
                                            <div class="input-group  mb-3">


                                                <input class="form-control" placeholder="Password" type="password"
                                                    [type]="showPassword ? 'text' : 'password'"
                                                    style="height:30px; font-size:12px;" [(ngModel)]="passwords"
                                                    name="passwords" (ngModelChange)="passwordChange($event)"
                                                    maxlength="25" (focus)="focusPassword()"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [type]="hide ? 'password' : 'text'">
                                                <!-- <div class="input-group-prepend"> -->
                                                <span id="basic-addon1">
                                                    <!-- <mat-icon class="placeholder" (click)="myFunction()">
                                                        {{hide ?
                                                        'visibility_off' : 'visibility'}}



                                                    </mat-icon> -->
                                                    <i class="material-icons ri" (click)="myFunction()"> {{hide ?
                                                        'visibility_off' : 'visibility'}}</i>

                                                </span>

                                            </div>
                                            <!-- <button type="button" id="btnToggle" class="toggle" (click)="toogle()"> -->
                                            <!-- <span *ngIf="show"class="material-icons">
                          visibility
                          </span>
                          <span *ngIf="!show" class="material-icons">
                            visibility-off
                            </span> -->

                                            <!-- </button> -->
                                            <div *ngIf="passwordError">
                                                <div class="text-danger">{{passwordErrorMsg}}</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label><input type="checkbox" name="rember" style="margin-right: 5px;"
                                                    (ngModelChange)="remChange($event)" [(ngModel)]="isChecked">Remember
                                                me</label>
                                        </div>
                                        <!-- <div class="form-group row">
                                      <label class="col-md-3 col-form-label" for="file-input">Doctor Photo</label>
                                      <div class="col-md-9">
                                       <input type="file" (change)="fileupload1($event)">
                                      </div>
                                    </div> -->
                                        <button class="btn btn-success btn-block mr-2 bt-lg"
                                            (click)="logintest1()">Login</button>
                                        <p style="font-size: 10px;
                                      text-align: center;
                                      margin-top: 10px;">Version : 26.07.23.01</p>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
<!-- <div class="alert-msgs" *ngIf="alertcon">
  <div class="alert-msg">
      <div class="alert-msg-con">

          <div class="alert-img" *ngIf="success">
              <img src="assets/img/alert/success.png" width="45px" alt="">
          </div>
          <div class="alert-img" *ngIf="warning">
              <img src="assets/img/alert/waring.png" width="45px" alt="">
          </div>
          <h4>
              {{alertmsg}}
          </h4>
          <p>{{alertcont}}</p>

          <button class="alert-btn {{button}}" type="button" (click)="hidealert()"> ok </button>
      </div>
  </div>
</div> -->
<script>
    // Automatically trigger the loading animation on click
    Ladda.bind('button[type=submit]');

    // Same as the above but automatically stops after two seconds
    Ladda.bind('button[type=submit]', { timeout: 2000 });

</script>