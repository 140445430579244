import { Component, OnInit, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router, RouterModule } from '@angular/router';
import { ApiService } from '../../../api.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ExcelService } from '../../../excel.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-pet-care-appointment',
  templateUrl: './pet-care-appointment.component.html',
  styleUrls: ['./pet-care-appointment.component.css']
})
export class PetCareAppointmentComponent implements OnInit {
  apiUrl = environment.apiUrl;
  imgUrl = environment.imageURL;
  searchQR: any;
  appointment_list: any;
  Main_list: any;
  filter_type: any;
  S_Date: any;
  E_Date: any;
  rows: any = 
  [{ type: "Dog", name: "dog1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" },
  { type: "Cat", name: "cat1" }]
  excelData: any[] = [];
  c_list: any = [];
  pet_type_lists: any;
  selectedpet_type :any
  pet_breed_types: any;
  selectedpet_breed :any
  health_issue_type: any;
  selectedhealth_issue_type:any;
  diagnosis: any;
  selecteddiagnosis:any;
  sub_diagnosis: any;
  selectedsub_diagnosis:any;
  locationsearch: any;
  isLoading:Boolean = false;
  constructor(
    private toastr: ToastrManager,
    private router: Router,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private _api: ApiService,
    private datePipe: DatePipe,
    private excelService: ExcelService,

  ) {
    // login_status
    if (this.getFromLocal("login_status") === false) {
      this.router.navigate(['login']);
    }
  }

  ngOnInit(): void {
    this.listpettype();
  
  
  }
  filterData() {
    if(this.S_Date <=  this.E_Date || this.selectedpet_type?.pet_type_title || this.selectedpet_breed ||this.selectedhealth_issue_type ||
      this.selecteddiagnosis?.diagnosis || this.selectedsub_diagnosis || this.locationsearch
    ) {
      this.listpettype()
    } else {
      this.toastr.warningToastr('Please Provide A Valid Filter')
    }
  }

  listpettype() {
    
    let data = {
      pet_type: this.selectedpet_type?.pet_type_title,
      pet_breed: this.selectedpet_breed,
      health_issue_title: this.selectedhealth_issue_type,
      diagnosis: this.selecteddiagnosis?.diagnosis,
      sub_diagnosis: this.selectedsub_diagnosis,
      clinic_loc: this.locationsearch,
      fromdate:"",
      todate:""
    };
  
    if (this.E_Date !== undefined && this.S_Date !== undefined) {
      let endDate = new Date(this.E_Date); // Copy the date to avoid modification of the original date.
      endDate.setDate(endDate.getDate() + 1);
  
      data.fromdate = this.datePipe.transform(new Date(this.S_Date), 'yyyy-MM-dd');
      data.todate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    }
    this.isLoading = true;
    this.appointment_list = [];
    this._api.appointment_list(data).subscribe(
      (response: any) => {
        console.log(response.Data);
        this.rows = response.Data;
        this.Main_list = response.Data;
        this.appointment_list = response.Data;
        this.isLoading = false;
        console.log(this.appointment_list);
        this.get_c_list();
        this.pet_type_list();
        this.health_issue_type_list();
        this.diagnosis_getlist();
      },
      (error: any) => {
        this.isLoading = false;
          this.appointment_list = [];
          this.Main_list = [];
          console.error('Error:', error);
          this.toastr.errorToastr('Failed to load appointments. Please try again.');
      }
    );
  }
  
clearfilter(){
  this.selectedpet_type = undefined;
  this.selectedpet_breed= undefined;
  this.selectedhealth_issue_type = undefined;
  this.selecteddiagnosis= undefined;
  this.selectedsub_diagnosis = undefined;
  this.locationsearch = undefined;
  this.S_Date = undefined;
  this.E_Date= undefined;
}
  pet_type_list(){

    this._api.pet_type_list().subscribe(
      (response: any) => {
        this.pet_type_lists = response.Data;
      }
    );
    
  }

  pet_breed_type(){
    let a = this.selectedpet_type
    console.log("this.pet_type",a);
    
    let data ={
      pet_type_id: this.selectedpet_type._id
    }
    this._api.pet_breed_type(data).subscribe(
      (response: any) => {
        this.pet_breed_types = response.Data;
      }
    );
    
  }

  health_issue_type_list(){
    this._api.health_issue_type_list().subscribe(
      (response: any) => {
        this.health_issue_type = response.Data;
    
      }
    );
  }


  diagnosis_getlist(){
    this._api.diagnosis_getlist().subscribe(
      (response: any) => {
        this.diagnosis = response.Data;
      }
    );
  }
  
  sub_diagnosis_getlist_byid(){
    let data ={
      diagnosis_id: this.selecteddiagnosis._id
    }

    this._api.sub_diagnosis_getlist_byid(data).subscribe(
      (response: any) => {
        this.sub_diagnosis = response.Data;
      }
    );
  }
  
  view_details(item) {
    this.saveInLocal('view_detail_data', item);
    this.saveInLocal('view_detail', 'Appointment')
    this.router.navigateByUrl('/admin/View_details')

  }
  saveInLocal(key, val): void {
    this.storage.set(key, val);
  }

  getFromLocal(key): any {
    return this.storage.get(key);
  }
  Filter(type) {
    this.appointment_list = this.Main_list;
    this.filter_type = type;
    if (this.filter_type == 'Completed') {
      this.appointment_list = this.appointment_list.filter((x: any) => x.appoinment_status == this.filter_type)
      console.log(this.appointment_list)
      this.get_c_list();
    }
    if (this.filter_type == 'Incomplete') {
      this.appointment_list = this.appointment_list.filter((x: any) => x.appoinment_status == this.filter_type)
      console.log(this.appointment_list)
      this.get_c_list();
    }
    if (this.filter_type == 'Missed') {
      this.appointment_list = this.appointment_list.filter((x: any) => x.appoinment_status == this.filter_type)
      console.log(this.appointment_list)
      this.get_c_list();
    }
    if (this.filter_type == 'All') {
      this.appointment_list = this.Main_list;
      console.log(this.appointment_list)
      this.get_c_list();
    }

  }
  Delete(id) {
    let a = {
      "_id": id
    }
    this._api.appointment_delete(a).subscribe(
      (response: any) => {
        console.log(response.Data);
        //alert('Deleted Successfully');
        this.showSuccess("Deleted Successfully")
        this.ngOnInit();
      }
    );
  }
  filter_date() {
    if (this.E_Date != undefined && this.S_Date != undefined) {
      // let yourDate = new Date(this.E_Date.getTime() + (1000 * 60 * 60 * 24));
      let yourDate = this.E_Date.setDate(this.E_Date.getDate() + 1);

      let a = {
        "fromdate": this.datePipe.transform(new Date(this.S_Date), 'yyyy-MM-dd'),
        "todate": this.datePipe.transform(new Date(yourDate), 'yyyy-MM-dd')
      }
      let element: HTMLElement = document.getElementsByClassName('ui-paginator-first')[0] as HTMLElement;
      element.click();
      console.log(a);
      this._api.appointment_filter_date(a).subscribe(
        (response: any) => {
          console.log(response.Data);
          this.appointment_list = response.Data;
          this.get_c_list();
        }
      );
    }
    else {
      this.showWarning("Please Provide A Valid Date Range.")
      //alert('Please Provide A Valid Date Range.');
    }

  }
  refersh() {
    this.listpettype(); this.E_Date = undefined; this.S_Date = undefined;
  }
  exportAsXLSX(): void {
    const excelDataForExport = this.appointment_list.map((item: any, index: number)=> ({
      'S.No': index + 1,
      'Client Name': `${item.user_id?.first_name} ${item.user_id?.last_name}`,
      'Phone Number': item.user_id?.user_phone,
      'Pet Type': item.family_id?.pet_type,
      'Pet Breed': item.family_id?.pet_breed !== "" ? item.family_id?.pet_breed : "-",
      'Pet Name': item.family_id?.pet_name,
      'Issue Type': item.health_issue_title,
      'Doctor Name': item.doc_business_info[0].dr_name,
      // 'Diagnosis': item.diagnosis !== "" ? item.diagnosis : "-",
      // 'Sub Diagnosis': item.sub_diagnosis !== "" ? item.sub_diagnosis : "-",
      'Appointment ID': item.appointment_UID !== "" ? item.appointment_UID : "-",
      'Appointment Date Time': item.booking_date_time,
      'Appointment Type': item.appointment_types,
      'Clinic Location': item.doc_business_info.length ? item.doc_business_info[0].clinic_loc : "-",
      'Appointment Status': item.appoinment_status,
      'Booking Date Time': item.booking_date_time,
      'Consultation Type': item.communication_type,
      'Amount': item.amount
    }));
  console.log(excelDataForExport)
    this.excelService.exportAsExcelFile(excelDataForExport, 'Pet Care Appointment');
  }
  
  get_c_list() {
    this.c_list = this.appointment_list.reverse();
    console.log(this.c_list)
    this.excelData = this.c_list.map((item: any, index: number)=> ({
      'S.No': index + 1,
      'Patient Name': `${item.user_id?.first_name} ${item.user_id?.last_name}`,
      'Phone Number': item.user_id?.user_phone,
      'Pet Type': item.family_id?.pet_type,
      'Pet Breed': item.family_id?.pet_breed !== "" ? item.family_id?.pet_breed : "-",
      'Pet Name': item.family_id?.pet_name,
      'Health Issue Title': item.health_issue_title,
      'Doctor Name': item.doc_business_info[0].dr_name,
      'Diagnosis': item.diagnosis !== "" ? item.diagnosis : "-",
      'Sub Diagnosis': item.sub_diagnosis !== "" ? item.sub_diagnosis : "-",
      'Appointment UID': item.appointment_UID !== "" ? item.appointment_UID : "-",
      'Booking Date Time': item.booking_date_time,
      'Appointment Type': item.appointment_types,
      'Clinic Location': item.doc_business_info.length ? item.doc_business_info[0].clinic_loc : "-",
      'Appointment Status': item.appoinment_status,
      'Communication Type': item.communication_type,
      'Amount': item.amount
    }));
    // for (let a = 0; a < this.c_list.length; a++) {
    //   let data = {  
    //   }
    //   this.excelData.push(this.c_list)
    // }

  }

  showSuccess(msg) {
    this.toastr.successToastr(msg);
  }

  showError(msg) {
    this.toastr.errorToastr(msg);
  }

  showWarning(msg) {
    this.toastr.warningToastr(msg);
  }
  getStatusLabel(status: string): string {
    return this._api.getStatusLabel(status)
  }
}
