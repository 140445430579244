import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css',
        '../../assets/webassets/vendor/aos/aos.css',
        // '../../assets/webassets/vendor/bootstrap/css/bootstrap.min.css',
        '../../assets/webassets/vendor/bootstrap-icons/bootstrap-icons.css',
      '../../assets/webassets/vendor/glightbox/css/glightbox.min.css',
    '../../assets/webassets/vendor/remixicon/remixicon.css',
      '../../assets/webassets/vendor/swiper/swiper-bundle.min.css',
    '../../assets/webassets/css/style.css']
})
export class WebsiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
