<!-- <h4 class="main-content-title tx-24 mb-1 pb-2"></h4> -->
<div class="page-header">
    <div>
        <h2 class="main-content-title tx-24 mb-1">Vendor Details</h2>

    </div>

</div>
<div class="row">
    <div class="col-md-4">
        <div class="search-hero">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                        <i class="fas fa-search"></i>
                    </span>
                </div>
                <span class="clearable">
                    <input class="form-control" type="text" name="search2" [(ngModel)]="searchQR" autocomplete="off"
                        placeholder="Search..." (input)="tt.filterGlobal($event.target.value, 'contains')"
                        (keypress)='research()' (blur)='research1()'>
                    <i *ngIf="shremove" class="clearable__clear"
                        (click)="tt.filterGlobal('', 'contains');remove()">&times;</i>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <!-- <h5 class="form-lable">Start Date</h5> -->
        <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="Start Date"></p-calendar>
        <!-- </div>
    <div class="col-md-3"> -->
        <!-- <h5 class="form-lable">End Date</h5> -->
        <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="End Date"></p-calendar>
        <!-- </div>
    <div class="col-md-3"> -->
        <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-bottom: 22px;"
            (click)="filter_date()"> <i class="fas fa-search pr-1"></i>Search</button>
        <button type="button" class="btn btn-secondary mr-2" style="margin-bottom: 22px;" (click)="refersh()"><i
                class="fas fa-sync-alt pr-1"></i> Refresh</button>
        <button type="button" class="btn btn-primary mr-2" style="margin-bottom: 22px;" (click)="addnewVendor()"> <i
                class="fas fa-plus-circle pr-1"></i>Add</button>
    </div>
</div>

<div class="ven-table">
    <div class="doc-table">
        <p-table [value]="rows | filter:searchQR" styleClass="ui-table-customers" #tt [paginator]="true" [rows]="15"
            [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['type','bussiness']">

            <ng-template pTemplate="header">
                <tr class="thhead" style="text-transform:capitalize">
                    <th class="th-1">
                        S.No
                    </th>
                    <th>
                        Business Name
                    </th>
                    <th class="th2">
                        Business email
                    </th>
                    <th>
                        Business  Number
                    </th>
                    <th>Business Location</th>
                    <!-- <th>Created by</th>
                        <th>Modified by</th> -->
                    <!-- <th>Govt ID proof</th>
                    <th>Photo ID proof</th> -->
                    <th class="th3">Created at</th>
                    <th>GST No </th>
                    <th>PAN No </th>
                    <!-- <th>Device type</th> -->
                    <th class="act text-center">Approve / Reject</th>
                    <!-- <th>Live / Block</th> -->
                    <th style="width: 163px;text-align:center">
                        Action
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-i="rowIndex">
                <tr class="tdrow" >
                    <td (click)="view_details(item)">{{i+1}}</td>
                    <td (click)="view_details(item)">{{item.bussiness_name}}</td>
                    <td (click)="view_details(item)">{{item.bussiness_email}} </td>
                    <td (click)="view_details(item)"> {{item.bussiness_phone}}</td>
                    <td (click)="view_details(item)"> {{item.bussiness_loc}}</td>
                    <!-- <td class="act">
                        <ng-container *ngFor="let doc of item.govt_id_proof">
                            <i (click)="goToLink1(doc.govt_id_proof)" class="fas fa-file-pdf"
                                style="cursor:pointer;background-color: #fff;color: #fd2c3a;" aria-hidden="true"></i>
                        </ng-container>
                    </td>
                    <td class="act">
                        <ng-container *ngFor="let doc of item.photo_id_proof">
                            <i (click)="goToLink1(doc.photo_id_proof)" class="fas fa-file-pdf"
                                style="cursor:pointer;background-color: #fff;color: #fd2c3a;" aria-hidden="true"></i>
                        </ng-container>
                    </td> -->
                    <td (click)="view_details(item)">
                        {{item.createdAt | date:'dd-MM-yyyy'}}
                    </td>

                    <!-- <td>
                        {{item.mobile_type}}
                    </td> -->
                    <td (click)="view_details(item)">{{item?.bussiness_gst?item?.bussiness_gst:'-'}}</td>
                    <td (click)="view_details(item)">{{item?.bussiness_pan?item?.bussiness_pan:'-'}}</td>
                    <td class="act text-center">

                        <button class="btn btn-success go btn-sm" (click)="verify('Verified',item._id)"
                            style="padding: 2px; line-height: 1.2;font-size: 12px !Important;"
                            *ngIf="item.profile_verification_status != 'Verified'">Approve</button>
                        <button class="btn btn-danger btnd btn-sm" (click)="verify('Not verified',item._id)"
                            style="padding: 2px; line-height: 1.2;font-size: 12px !Important;"
                            *ngIf="item.profile_verification_status == 'Verified'">Reject</button>


                        <!-- <i class="fa fa-check-circle-o" *ngIf="item.profile_verification_status == 'Not verified' "
                            (click)="verify('Verified',item._id)" aria-hidden="true" style="color: rgb(2, 187, 57);"
                            pTooltip="Approve"></i>
                        <i class="fa fa-times-circle-o" *ngIf="item.profile_verification_status == 'Verified' "
                            (click)="verify('Not verified',item._id)" aria-hidden="true"
                            style="color: rgb(223, 26, 26);" pTooltip="Reject"></i> -->
                    </td>
                    <!-- <td class="act">
                            <i class="fa fa-check-circle-o" aria-hidden="true" style="color: rgb(2, 187, 57);"
                                pTooltip="Live"></i>
                            <i class="fa fa-times-circle-o" aria-hidden="true" style="color: rgb(223, 26, 26);"
                                pTooltip="Block"></i>
                        </td> -->

                    <td class="act text-center">
                        <i style="color: #2196f3;" (click)="goToEditVendor(item._id)" class="fas fa-edit" aria-hidden="true" pTooltip="Edit"></i>
                        <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete" (click)="del(item.user_id)"></i> -->
                        <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true" pTooltip="View"
                            (click)="view_details(item)"></i>
                        <!-- <i style="color: #000000;" class="fa fa-list" aria-hidden="true" pTooltip="Product List"
                            (click)="viewProduct(item._id)"></i> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" >
                <tr class="full text-center" *ngIf="isLoading" >
                    <td colspan="10" >
                      <div class="custom-flex">
                     <img class="img-fluid loader-logo" src="assets/images/dogloader.gif" alt="loader" > <div class="fs">Loading....</div> </div>
                    </td>   
                  </tr>
                <tr class="full text-center" *ngIf="rows.length==0 && !isLoading">
                    <td colspan="10" >
                      <div class="custom-flex">
                                                <img class="img-fluid loader-logo" src="assets/images/nodataimage.png" alt="Logo" >
                                               <div class="fs">No records found</div> </div>
                    </td>
                  </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" >
                <tr  *ngIf="rows?.length !=0 && !isLoading">
                    <td colspan="10"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>