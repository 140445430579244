<h4 class="title">Health Checkup Type</h4>

<div class="row" style="padding: 0px 0px 23px 10px;">
    <div class="col-md-12">
        <div class="card-box">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="form-lable">Checkup Type:</h5>
                    <input type="text" pInputText [(ngModel)]="checkup_type" placeholder="Enter Checkup Type" >
                </div>
                <div class="col-md-4">
                  <h5 class="form-lable">Checkup Type Icon:</h5>
                  <input type="file" (change)="getUploadFile($event)" placeholder="Choose Checkup Type ICON" >
              </div>
                <div class="col-md-4">
                    <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;" (click)="addCheckupType()">Add</button>
                </div>
        </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="search-hero">
            <div class="input-group mb-3">
               <div class="input-group-prepend">
                 <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
               </div>
               <input class="form-control" type="text" name="search2"
           [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
             </div>
         </div>
    </div>
</div>

<div class="row">
  <div class="col-md-4">
      <h5 class="form-lable">Start Date</h5>
      <p-calendar  [showIcon]="true" inputId="icon"></p-calendar>
  </div>
  <div class="col-md-4">
      <h5 class="form-lable">End Date</h5>
      <p-calendar  [showIcon]="true" inputId="icon"></p-calendar>
  </div>
</div>

<p-table [value]="rows | filter:searchQR" styleClass="ui-table-customers" #tt [paginator]="true" [rows]="5"
    [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['type']">
    <!-- <ng-template pTemplate="caption">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="50">
                <h5 style="color:#fff;">Entry Type List</h5>
            </div>
            <div fxFlex="50">
                <div class="ui-table-globalfilter-container">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Entry Type">
                </div>
            </div>

        </div>

    </ng-template> -->
    <ng-template pTemplate="header" >
        <tr class="thhead">
            <th class="th-1">
                S.No
            </th>
            <th>
                Type
            </th>
            <th>
              Icon
          </th>
            <th>Created by</th>
            <th>Modified by</th>
            <th>Created at</th>
            <th>Modified at</th>
            <th style="width: 163px;text-align:center">
                Action
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" *ngFor="let item of CheckupList; let i = index;" >
        <tr  class="tdrow">
            <td>{{i+1}}</td>
            <td>{{item.name}}</td>
            <td>
              <img _ngcontent-dah-c159="" src="{{item.imgurl}}" alt="Denim Jeans" style="width: 50%;">
          </td>

            <td>
                {{item.createdby}}
            </td>
            <td>
                {{item.modifiedby}}
            </td>
            <td>
                {{item.createdat}}
            </td>
            <td>
                {{item.modified_at}}
            </td>
            <td class="act">
                <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" pTooltip="Edit" (click)="editCheckupType(item.id)"></i>
                <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete"></i> -->
                <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true"  pTooltip="View" (click)="viewCheckupType(item.id)"></i>
            </td>
        </tr>
    </ng-template>
</p-table>
