// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,


    //Dev URL///

  apiUrl: 'https://petfolio.in/api/',
  imageURL: 'https://petfolio.in/api/upload',


  //Live URL//


  // apiUrl: 'http://192.168.0.147:4000/api/',
  // imageURL: 'http://192.168.0.139:3000/upload'




  // apiUrl: 'http://localhost:91/'
  // 34.211.194.144:3000
};
