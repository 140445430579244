<div class="page-header">
    <div>
        <h2 class="main-content-title tx-24 mb-1">{{editMode?'Update':'Create'}} Vendor </h2>

    </div>
    <div class="d-flex">
        <button class="btn btn-sm btn-secondary " (click)="cancel1()"> <i class="fas fa-arrow-left ml-1"
                aria-hidden="true"></i> Back</button>
    </div>

</div>



<div class="card-box" *ngIf="addfirst">
    <!-- <form [formGroup]="userForm">
        <div class="form-row">
            <div class="form-group col-6">
                <label for="">First Name</label>
                <input type="text" class="form-control" formControlName="first_name">
            </div>
            <div class="form-group col-6">
                <label for="">Last Name</label>
                <input type="text" class="form-control" formControlName="last_name">
            </div>
            <div class="form-group col-6">
                <label for="">Email</label>
                <input type="text" class="form-control" formControlName="user_email">
            </div>
            <div class="form-group col-6">
                <label for="">Mobile</label>
                <input type="number" class="form-control" formControlName="user_phone">
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-sm btn-primary" (click)="addUser()">ADD</button>
        </div>
    </div> -->
    <div class="row" style="padding: 0px 0px 23px 10px;">
        <div class="row">
            <div class="col-md-6 mb-3">
                <h5 class="form-lable required">First Name :</h5>
                <input type="text" autofocus="autofocus" [readonly]="editMode" pInputText [(ngModel)]="tittle" placeholder="First Name"
                    (keydown)="keydown($event)">
                <div *ngIf="Validation == false && (tittle == undefined || tittle.trim() == '') " class="mt-1 error">
                    <div class="text-danger">First Name is Required.</div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <h5 class="form-lable required">Last Name:</h5>
                <input type="text" pInputText [(ngModel)]="Name" [readonly]="editMode" placeholder="Last Name" (keydown)="keydown($event)">
                <div *ngIf="Validation == false && (Name == undefined || Name.trim() == '' )" class="mt-1 error">
                    <div class="text-danger">Last Name is Required.</div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <h5 class="form-lable required">Email ID:</h5>
                <input type="text" pInputText [(ngModel)]="Email" [readonly]="editMode" (ngModelChange)="EmailidChange($event)"
                    [disabled]="type == 'edit'" placeholder="Email ID">
                <div *ngIf="Email_idError" class="mt-1 error">
                    <div class="text-danger">Valid Email Address Required.</div>
                </div>
                <div *ngIf="Validation == false && Email_id == undefined" class="mt-1 error">
                    <div class="text-danger"> Email Address Required.</div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <h5 class="form-lable required">Phone Number:</h5>
                <input type="text" pInputText [(ngModel)]="Phone" [readonly]="editMode" placeholder="Phone Number" maxlength="10"
                    [disabled]="type == 'edit'" (keypress)=_keyPress($event)>
                <div *ngIf="Validation == false && (Phone == undefined || Phone == ''  || Phone.length != 10)"
                    class="mt-1 error">
                    <div class="text-danger">Phone Number is Required.</div>
                </div>
            </div>
            <div class="col-md-12 mt-20" *ngIf="userid == undefined">
                <!-- <button type="button" class="btn btn-primary" (click)="create_1()">Continue</button> -->
                <!-- <button type="button" class=" btn btn-danger" (click)="cancel1()">Cancel</button> -->
            </div>
        </div>
    </div>
</div>

<div class="card-box" *ngIf="addmore" >
    <!-- <form [formGroup]="addVendorForm"> -->
    <div class="form-row">
        <div class="form-group col-6">
            <label for="">Business Name:</label><span style="color: red;font-size: large;">*</span>
            <input type="text" placeholder="Enter Business Name" class="form-control" [(ngModel)]="business_name" (keydown)="keydown($event)">
            <div *ngIf="Validation == false && (business_name == undefined || business_name == '' )" class="mt-1 error">
                <div class="text-danger">Business Name is Required.</div>
            </div>
        </div>
        <div class="form-group col-6">
            <label for="">Business Email:</label><span style="color: red;font-size: large;">*</span>
            <input type="email" placeholder="Enter Business Email" class="form-control" [(ngModel)]="business_email"
                (ngModelChange)="EmailidChange($event)">
            <div *ngIf="Email_idError" class="mt-1 error">
                <div class="text-danger">Enter Valid Email Address</div>
            </div>
            <div *ngIf="Validation == false && (business_name == undefined || business_name == '' )" class="mt-1 error">
                <div class="text-danger">Business Name is Required.</div>
            </div>
        </div>
        <div class="form-group col-6">
            <label for="">Business:</label><span style="color: red;font-size: large;">*</span>
            <input type="text" placeholder="Enter Business" class="form-control" [(ngModel)]="business">
            <div *ngIf="Validation == false && (business == undefined || business == '' )" class="mt-1 error">
                <div class="text-danger">Business is Required.</div>
            </div>
        </div>
        <div class="form-group col-6">
            <label for="">Phone:</label><span style="color: red;font-size: large;">*</span>
            <input type="text" placeholder="Enter Phone" class="form-control" [(ngModel)]="business_phone" maxlength="10"
                (keypress)=_keyPress($event)>
            <div *ngIf="Validation == false && (business_phone == undefined || business_phone == '' )"
                class="mt-1 error">
                <div class="text-danger">Phone Name is Required.</div>
            </div>
        </div>
        <div class="form-group col-12">
            <label for="">Business Registration Details:</label><span style="color: red;font-size: large;">*</span>
            <textarea cols="10" rows="5" placeholder="Business Registration Details" class="form-control" [(ngModel)]="business_reg"></textarea>
            <div *ngIf="Validation == false && (business_reg == undefined || business_reg == '' )" class="mt-1 error">
                <div class="text-danger">Business Registration Details is Required.</div>
            </div>
        </div>
        <!-- <div class="col-6">
            <h5 class="form-lable">Business Location:<span style="color: red;font-size: large;">*</span></h5>
            <input class="form-control" [(ngModel)]="address1" style="padding:10px;"
                 [options]='options' 
                 />
                 <textarea cols="10" rows="5" class="form-control" placeholder="bussiness Location"[(ngModel)]="address1"></textarea>
                 #placesRef="ngx-places"ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
            <div *ngIf="Validation == false && (address1 == undefined || address1 == '' )" class="mt-1 error">
                <div class="text-danger">Business Location is Required.</div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-6">
            <h5 class="form-lable">Business Location:<span style="color: red;font-size: large;">*</span></h5>
            <input class="form-control" [(ngModel)]="address1" style="padding:10px; width:75%"
                 [options]='options' #placesRef="ngx-places"ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                 />
                 
            <div *ngIf="Validation == false && (address1 == undefined || address1 == '' )" class="mt-1 error">
                <div class="text-danger">Business Location is Required.</div>
            </div>
        </div>
        <div class="map">
            <agm-map [latitude]="base_lat" [longitude]="base_lng" [zoom]="zoom" [disableDefaultUI]="false"
                [zoomControl]="false">

                <agm-marker [latitude]="location_lat" [longitude]="location_lng" [markerDraggable]="true"
                    (dragEnd)="markerDragEnd($event)">

                    <agm-info-window>
                        <strong>{{address}}</strong>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    </div>
   <div class="row mt-5">
    <div class="col-md-6 mb-4">
        <h5 class="form-lable required"> GST No:</h5>
        <input type="text" class="form-control" [(ngModel)]="bussiness_gst" placeholder="Enter  GST No">
    </div>
    <div class="col-md-6 mb-4">
        <h5 class="form-lable required"> PAN No:</h5>
        <input type="text" class="form-control" [(ngModel)]="bussiness_pan" placeholder="Enter  PAN No">
    </div>
    <div class="col-md-6" style="margin-bottom: 15px;">
        <h5 class="form-lable required">Govt ID proof: <span style="color: red;font-size: large;">*</span></h5>
        <input type="file" #imgType (change)="fileupload($event,'Govt')" id="imgfile"
            accept=".png, .jpg, .jpeg, .pdf" class="hei form-control" id="city"
            placeholder="User type icon">
        <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
            Image(jpeg, png, jpg) max 2 mb </p>
        <div *ngIf="Validation == false && !govt_id_proof" class="mt-1 error">
            <div class="text-danger">Govt ID proof is Required.</div>
        </div>
        <table *ngIf="govt_id_proof" class="spec-table" style="margin-top: 8px;">
            <thead>
                <th>S.No</th>
                <th>Govt ID proof</th>
                <th>Remove</th>
            </thead>
            <tbody>
                <tr >
                    <td>1</td>
                    <td><img src="{{govt_id_proof}}" style="width: 20px;"></td>
                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                            pTooltip="Delete" (click)="remove_govt_id_proof()"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-6">
        <h5 class="form-lable required">Photo ID proof: <span style="color: red;font-size: large;">*</span></h5>
        <input type="file" #imgType (change)="fileupload($event,'Photo')" id="imgfile"
            accept=".png, .jpg, .jpeg, .pdf" class=" hei form-control" id="city"
            placeholder="User type icon">
        <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
            Image(jpeg, png, jpg) max 2 mb </p>
        <div *ngIf="Validation == false &&  !photo_id_proof"class="mt-1 error">
            <div class="text-danger">Photo ID proof is Required.</div>
        </div>
        <table *ngIf="photo_id_proof" class="spec-table" style="margin-top: 8px;">
            <thead>
                <th>S.No</th>
                <th>Photo ID proof</th>
                <th>Remove</th>
            </thead>
            <tbody>
                <tr >
                    <td>1</td>
                    <td><img src="{{photo_id_proof}}" style="width: 20px;"></td>
                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                            pTooltip="Delete" (click)="remove_photo_id_proof()"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-6">
        <h5 class="form-lable required">Certificate: <span style="color: red;font-size: large;">*</span></h5>
        <input type="file" #imgType (change)="fileupload($event, 'Certificate')" id="imgfile"
            accept=".png, .jpg, .jpeg, .pdf" class=" hei form-control" id="city"
            placeholder="User type icon">
        <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
            Image(jpeg, png, jpg) max 2 mb </p>
        <div *ngIf="Validation == false && certificate_arr.length == 0 " class="mt-1 error">
            <div class="text-danger">Certificate is Required.</div>
        </div>
        <table *ngIf="certificate_arr.length > 0 " class="spec-table" style="margin-top: 8px;">
            <thead>
                <th>S.No</th>
                <th>Certificate</th>
                <th>Remove</th>
            </thead>
            <tbody>
                <tr *ngFor="let arr of certificate_arr ; let i= index;">
                    <td>{{i+1}}</td>
                    <td><img src="{{arr.certificate_pic}}" style="width: 20px;"></td>
                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                            pTooltip="Delete" (click)="remove_Certificate_img(i)"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-6">
        <h5 class="form-lable required">Business Gallery: <span style="color: red;font-size: large;">*</span></h5>
        <input type="file" #imgType (change)="fileupload($event, 'Business')" id="imgfile"
            accept=".png, .jpg, .jpeg, .pdf" class=" hei form-control" id="city"
            placeholder="User type icon">
        <p style="font-size: 12px;color: gray;text-align: right;">Note : Upload only pdf or
            Image(jpeg, png, jpg) max 2 mb </p>
        <div *ngIf="Validation == false && business_gallery.length == 0 " class="mt-1 error">
            <div class="text-danger">Business Gallery is Required.</div>
        </div>
        <table *ngIf="business_gallery.length > 0 " class="spec-table" style="margin-top: 8px;">
            <thead>
                <th>S.No</th>
                <th>Business Gallery</th>
                <th>Remove</th>
            </thead>
            <tbody>
                <tr *ngFor="let arr of business_gallery ; let i= index;">
                    <td>{{i+1}}</td>
                    <td><img src="{{arr.bussiness_gallery}}" style="width: 20px;"></td>
                    <td><i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                            pTooltip="Delete" (click)="remove_business_gallery_img  (i)"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
   </div>
  
    <!-- </form> -->
    <div class="row mt-20">
        <div class="col-12 d-flex justify-content-center">
            <button  class="btn mt-20 btn-primary  dr-btn text-white" (click)="create_1()">{{editMode?'Update':'Create'}}</button>
        </div>
    </div>
</div>