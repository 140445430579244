<section style="text-transform:capitalize">
    <div class="page-header ">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Customer Management</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Customers</li>
            </ol> -->
        </div>

    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <span class="clearable">
                                            <input class="form-control"
                                                type="text" name="search2"
                                                [(ngModel)]="searchQR"
                                                autocomplete="off"
                                                placeholder="Search..."
                                                (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                (keypress)='research()'
                                                (blur)='research1()'>
                                            <i *ngIf="shremove"
                                                class="clearable__clear"
                                                (click)="tt.filterGlobal('', 'contains');remove()">&times;</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-center">
                                <p-calendar [(ngModel)]="S_Date"
                                    [showIcon]="true" inputId="icon"
                                    placeholder="Start Date"
                                    [readonlyInput]="true" class></p-calendar>
                                <p-calendar [(ngModel)]="E_Date"
                                    [showIcon]="true" inputId="icon"
                                    placeholder="End Date"
                                    [readonlyInput]="true"
                                    class="mr-1"></p-calendar>
                                <button type="button" class="btn btn-primary"
                                    (click)="filter_date()"><i
                                        class="fas fa-search"></i>
                                    Search</button>
                                <!-- </div>
                            <div class="col-md-3 text-right"> -->
                                <button type="button"
                                    class="btn btn-secondary mr-2"
                                    (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i>
                                    Refresh</button>
                                <button type="button"
                                    class="btn btn-primary mr-0"
                                    (click)="service_form()"><i
                                        class="fas fa-plus-circle"></i>
                                    Add</button>
                            </div>

                        </div>

                        <div class="doc-table table-responsive" >

                            <div class="ui-helper-clearfix"
                                style="text-align: left">
                                <button type="button" pButton
                                    icon="pi pi-file-o" iconPos="left"
                                    label="EXCEL"
                                    (click)="exportAsXLSX()"
                                    style="margin-right: 0.5em; margin-bottom: 10px;"></button>
                                <!--  <button type="button" pButton icon="fas fa-file-o" iconPos="left" label="All Data" (click)="dt.exportCSV()" style="float:left"></button>          -->
                            </div>

                            <!-- <p-table [value]="user_list " styleClass="ui-table-customers" #tt [paginator]="true" [rows]="15"
    [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true" #dt
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['first_name','user_email','user_phone']"> -->
                            <p-table #tt [value]="user_list | filter:searchQR" dataKey="id"
                                styleClass="p-table-customers"
                                [rowHover]="true" [rows]="10"
                                [showCurrentPageReport]="true"
                                [rowsPerPageOptions]="[10, 25, 50]"
                                [paginator]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']">

                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <!-- <th>
                Profile Image
            </th> -->
                                     
                                        <th>
                                            Name
                                        </th>
                                        <th >
                                            Email
                                        </th>
                                        <th >
                                            Phone
                                        </th>
                                      
                                        <!-- <th>
              Delete Status
            </th>
            <th>
              email Status
            </th> -->

                                        <!-- <th>Created by</th>
            <th>Modified by</th> -->
                                        <th>Created Date</th>
                                        <!-- <th>Last Login Date</th> -->
                                        <!-- <th>Device type</th> -->
                                        <th style="text-align:center">
                                            Action
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item
                                    let-i="rowIndex">
                                    <tr class="tdrow" 
                                        style="text-transform: none;">
                                        <td (click)="view_details(item)">{{i+1}}</td>
                                        <!-- <td>
                                            <div class="imgthumcontainer"><img
                                                    src="{{item.profile_img}}"
                                                    alt> </div>
                                            <span
                                                style="display:none">{{item.profile_img}}</span>
                                        </td> -->
                                        <td (click)="view_details(item)"
                                            class="truncate-cell">{{item.first_name}}
                                            {{item.last_name}}</td>
                                        <td (click)="view_details(item)"
                                            class="truncate-cell">{{item.user_email}}</td>
                                        <td (click)="view_details(item)">{{item.user_phone}}</td>

                                    
                                        <!-- <td>
                {{item.delete_status}}
            </td>

            <td>
              {{item.user_email_verification}}
            </td> -->
                                        <td (click)="view_details(item)">
                                            {{item.createdAt | date}}
                                        </td>
                                        <!-- <td>
                                            {{item.mobile_type}}
                                            </td > -->
                                        <td class="act">
                                            <!-- <i style="color: #4CAF50;" class="fas fa-paw" aria-hidden="true"  pTooltip="View Pet" (click)="pet_view(item)"></i> -->
                                            <i
                                                style="color: #31ccfc;"
                                                class="fas fa-info-circle"
                                                aria-hidden="true"
                                                (click)="view_details(item)"
                                                pTooltip="View Details"></i>
                                            <!-- <i style="color: #1b1b1b;" class="fa fa-ban" aria-hidden="true"  pTooltip="Block"></i> -->
                                            <!-- <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" (click)="edit_details(item)" pTooltip="Edit"></i>-->
                <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" (click)="Delete(item._id)"  pTooltip="Delete"></i> 
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" >
                                    <tr class="full text-center" *ngIf="isLoading" >
                                        <td colspan="6" >
                                          <div class="custom-flex">
                                          <div class="spinner-grow spinner-grow-sm bg-info"  role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div> <div class="fs">Loading....</div> </div>
                                        </td>
                                      </tr>
                                    <tr class="full text-center" *ngIf="user_list.length==0 && !isLoading">
                                        <td colspan="6" >
                                          No Records Found
                                        </td>
                                      </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" >
                                    <tr  *ngIf="user_list?.length !=0 && !isLoading">
                                        <td colspan="6"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</section>