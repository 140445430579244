<section>
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Add Product Detail</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Add Product Detail</li>
            </ol> -->
        </div>
    </div>
    <div class="row row-sm">
        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="row">
                            <div class="col-md-4" style="margin-bottom: 15px;">
                                <h5 class="form-lable">Vendor :<span style="color: red;font-size: large;">*</span></h5>
                                <p-dropdown [options]="vendor_list" [(ngModel)]="vendor_id" placeholder="Select Vendor"
                                    optionLabel="bussiness_name" [showClear]="true"></p-dropdown>
                                <div *ngIf="Validation == false && (vendor_id == undefined || vendor_id == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Vendor is Required.</div>
                                </div>

                            </div>
                            <div class="col-md-4" style="margin-bottom: 15px;">
                                <h5 class="form-lable">Category :<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <p-dropdown [options]="Catagories_list" [(ngModel)]="cat_id" class="m-0"
                                    placeholder="Select Category" optionLabel="product_cate" [showClear]="true">
                                </p-dropdown>
                                <div *ngIf="Validation == false && (cat_id == undefined || cat_id == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Category is Required.</div>
                                </div>
                            </div>
                            <div class="col-md-4" style="margin-bottom: 15px;">
                                <h5 class="form-lable">Product Name :<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="product_name"
                                    placeholder="Enter Product Name" maxlength="25">
                                <div *ngIf="Validation == false && (product_name == undefined || product_name == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Product Name is Required.</div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-2">
                                <h5 class="form-lable">Description :<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <textarea pInputTextarea placeholder="Enter Description" [(ngModel)]="product_discription" rows="5"
                                    cols="50"></textarea>
                                <div *ngIf="Validation == false && (product_discription == undefined || product_discription == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Description is Required.</div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-md-4 mb-2">
                                <h5 class="form-lable">Cost :<span style="color: red;font-size: large;">*</span></h5>
                                <input type="text" pInputText [(ngModel)]="cost" placeholder="Cost"
                                    (keypress)=_keyPress($event)>
                                <div *ngIf="Validation == false && (cost == undefined || cost == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Cost is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-4 mb-2">
                                <h5 class="form-lable">Threshold :<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="threshold" placeholder="Threshold"
                                    (keypress)=_keyPress($event)>
                                <div *ngIf="Validation == false && (threshold == undefined || threshold == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Threshold is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-4 mb-2">
                                <h5 class="form-lable">Price Type :<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="price_type" placeholder="Price Type">
                                <div *ngIf="Validation == false && (price_type == undefined || price_type == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Price type is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-2">
                                <h5 class="form-lable">Condition :<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <input type="text" pInputText [(ngModel)]="condition" placeholder="Condition">
                                <div *ngIf="Validation == false && (condition == undefined || condition == '' )"
                                    class="mt-1 error">
                                    <div class="text-danger">Condition is Required.</div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-2">
                                <h5 class="form-lable">Additional Details:<span
                                        style="color: red;font-size: large;">*</span></h5>
                                <div class="row">
                                    <div class="col-md-8">
                                        <input type="text" pInputText [(ngModel)]="addtion_info_text"
                                            placeholder="Additional Details">
                                        <div *ngIf="Validation == false && addition_detail.length == 0 "
                                            class="mt-1 error">
                                            <div class="text-danger">Additional Details is Required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <button (click)="add_addition_info()" class="btn btn-sm btn-primary"><i
                                                class="fa fa-plus"></i>Add Info</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" *ngIf="addition_detail.length > 0">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;">Additional Details</th>
                                                    <th style="text-align: center;">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let dynamic of addition_detail; let i = index;">
                                                    <td>{{dynamic}}</td>
                                                    <td style="text-align: center;"> <button
                                                            (click)="delete_addition_info(dynamic,i)"
                                                            class="btn btn-sm btn-danger">Delete</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="col-md-6 mb-2">
                                <h5 class="form-lable">Product image:<span style="color: red;font-size: large;">*</span>
                                </h5>
                                <div class="form-group row">
                                    <div class="col-md-7">
                                        <input type="file" class="form-control" #imgType1 (change)="fileupload($event)"
                                            id="imgFile" accept=".png, .jpg, .jpeg">

                                        <!-- <span class="img-size">Image Size Should be W 700 X H 350</span> -->
                                        <div *ngIf="Validation == false && Thmp_list.length == 0 " class="mt-1 error">
                                            <div class="text-danger">Product Image is Required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="imgthumcontainer"><img *ngIf="img_path != undefined"
                                                src="{{img_path}}"></div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="">
                                            <button (click)="addimg()" class="btn btn-sm btn-primary"><i
                                                    class="fa fa-plus"></i> Add Image</button>
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="Thmp_list.length > 0">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;">Image</th>
                                                    <th style="text-align: center;">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let dynamic of Thmp_list; let i = index;">
                                                    <td style="text-align: center;">
                                                        <div class="imgthumcontainer"
                                                            style="width: 30px; height:30px;display: inline-block;"><img
                                                                src="{{dynamic.product_img}}"></div>
                                                    </td>
                                                    <td style="text-align: center;"> <button
                                                            (click)="deleteimg(dynamic,i)"
                                                            class="btn btn-sm btn-danger">Delete</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <h5 class="form-lable">Thumbnail Image:<span
                                        style="color: red;font-size: large;">*</span></h5>
                                <div class="form-group row">
                                    <div class="col-md-7">
                                        <input type="file" class="form-control" #imgType2 (change)="fileupload2($event)"
                                            id="imgFile" accept=".png, .jpg, .jpeg">

                                        <!-- <span class="img-size">Image Size Should be W 200 X H 200</span> -->
                                        <div *ngIf="Validation == false && (thumbnail_image == undefined || thumbnail_image == '' )"
                                            class="mt-1 error">
                                            <div class="text-danger">Thumbnail Image is Required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="imgthumcontainer"><img *ngIf="thumbnail_image != undefined"
                                                src="{{thumbnail_image}}"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" style="padding-top: 20px;text-align: center;">
                                <button type="button"
                                    style="height: 40px;min-width: 130px !important;font-size: 18px!important;"
                                    class="btn btn-primary" (click)="create()"><i class="fas fa-plus-circle"></i>
                                    Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>