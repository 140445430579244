<section>
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1"> </h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>

    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <input class="form-control" type="text" name="search2" autocomplete="off"
                                            placeholder="Search...">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-lg-right text-sm-left">
                                <p-calendar [showIcon]="true" inputId="icon" placeholder="Start Date" class="mr-2">
                                </p-calendar>
                                <p-calendar [showIcon]="true" inputId="icon" placeholder="End Date" class="mr-2">
                                </p-calendar>
                                <button type="button" class="btn btn-primary"><i class="fas fa-search"></i>
                                    Search</button>
                            </div>

                        </div>
                        <!-- search end -->
                        <div class="row mt-2">
                            <div class="col-md-12 text-right">
                                <button (click)="Filter('Completed')" type="button"
                                    class="btn btn-success btn-sm mr-2"><i class="fas fa-check-circle"></i>
                                    Completed</button>
                                <button (click)="Filter('Incomplete')" type="button"
                                    class="btn btn-primary btn-sm mr-2"><i class="far fa-check-circle"></i>
                                    Booked</button>
                                <button (click)="Filter('Missed')" type="button" class="btn btn-danger btn-sm mr-2"><i
                                        class="fas fa-times-circle"></i> Cancelled</button>
                                <button (click)="refersh()" type="button" class="btn btn-secondary btn-sm mr-2"><i
                                        class="fas fa-sync-alt"></i> Refresh</button>

                            </div>
                        </div>

                        <div class="doc-table table-responsive">
                            <div class="ui-helper-clearfix" style="text-align: left">
                                <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="EXCEL"
                                    (click)="exportAsXLSX()" style="margin-right: 0.5em; margin-bottom: 10px;"></button>
                                <!--  <button type="button" pButton icon="fas fa-file-o" iconPos="left" label="All Data" (click)="dt.exportCSV()" style="float:left"></button>          -->
                            </div>
                            <p-table [value]="appointment_list | filter:searchQR" styleClass="ui-table-customers" #tt
                                [paginator]="true" [rows]="5" [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']">
                                <!-- <ng-template pTemplate="caption">
                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                <div fxFlex="50">
                                    <h5 style="color:#fff;">Entry Type List</h5>
                                </div>
                                <div fxFlex="50">
                                    <div class="ui-table-globalfilter-container">
                                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                        <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Entry Type">
                                    </div>
                                </div>

                            </div>

                        </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <!-- <th>
                                    Order No
                                </th> -->
                                        <th>
                                            Service Type
                                        </th>
                                        <!-- <th>
                                    Client name
                                </th> -->
                                        <!-- <th>Client phone </th> -->
                                        <th>Pet Type</th>
                                        <th>Pet Breed</th>
                                        <th>Pet Name</th>
                                        <th>Service Amount</th>
                                        <th>Service Provider Name</th>
                                        <!-- <th>Appointment Date & Time</th> -->
                                        <!-- <th>Appointment Type </th> -->
                                        <!-- <th>Description</th> -->
                                        <th>Booking Date & Time</th>
                                        <th>Status</th>
                                        <th style="width: 110px;text-align:center">
                                            Action
                                        </th>
                                        <!-- <th>Created by</th>
                                <th>Modified by</th>
                                <th>Created at</th>
                                <th>Modified at</th>
                                <th style="width: 110px;text-align:center">
                                    Action
                                </th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item let-i="rowIndex">
                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <!-- <td>12345</td> -->
                                        <td>{{item.service_name}}</td>
                                        <!-- <td>Vignesh</td>
                                <td>9876543210</td> -->
                                        <td>{{item.pet_id.pet_type}}</td>
                                        <td>{{item.pet_id.pet_breed}}</td>

                                        <td>{{item.pet_id.pet_name}}</td>
                                        <td>{{item.service_amount}}</td>
                                        <td><span
                                                *ngFor="let item of item.sp_business_info">{{item.bussiness_name}}</span>
                                        </td>
                                        <td>{{item.booking_date_time}}</td>
                                        <!-- <td>Online</td> -->
                                        <!-- <td>Lorem ipsum, or lipsum as...</td> -->
                                        <td>{{item.appoinment_status}}</td>
                                        <!-- <td>9/15/2020 1:45 PM</td>
                               <td>
                                    User
                                </td>
                                <td>
                                    Admin
                                </td>
                                <td>
                                    16-09-2020
                                </td>
                                <td>
                                    17-09-2020
                                </td>
                                <td class="act">
                                    <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" pTooltip="Edit"></i>
                                    <i style="color: #4CAF50;" class="fa  fa-clock-o" aria-hidden="true" pTooltip="Reschedule"></i>
                                    <i style="color: #fd2c3a;" class="fa fa-ban" aria-hidden="true" pTooltip="Disable"></i>
                                </td> -->
                                        <td class="act">
                                            <i style="color: #20d867;" class="fas fa-eye" aria-hidden="true"
                                                pTooltip="View"
                                                (click)="goto('service_provider_appointment_view', item)"></i>
                                            <!-- <i style="color: #4CAF50;" class="fa  fa-clock-o" aria-hidden="true" pTooltip="Reschedule"></i> -->
                                            <!-- <i style="color: #fd2c3a;" class="fa fa-ban" aria-hidden="true" pTooltip="Disable"></i> -->
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</section>