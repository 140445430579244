<section>
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Priscription Details</h2>

            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                    Details</li>
            </ol>
        </div>

        <div class="d-flex">
            <button class="btn btn-sm btn-secondary " (click)="back()">Back <i
                    class="fas fa-arrow-left ml-1"
                    aria-hidden="true"></i></button>
        </div>
    </div>

    <div class="card-box">
        <div class="row" style="padding: 0px 0px 23px 10px;">

            <div class="col-12">
                <div class="row table-responsive">

                    <table>
                        <tbody>
                            <tr>
                                <th scope="col"
                                    class="border">Prescription ID : </th>
                                <td>{{prescription_getlist?.Prescription_id}}</td>
                            </tr>

                            <tr>
                                <th scope="col"
                                    class="border">Diagnosis : </th>
                                <td>{{prescription_getlist?.diagnosis}}</td>
                            </tr>

                            <tr>
                                <th scope="col"
                                    class="border">Sub Diagnosis : </th>
                                <td>{{prescription_getlist?.sub_diagnosis}}</td>
                            </tr>
                            <!-- <tr>
                                <th scope="col"
                                    class="border">Appointment ID : </th>
                                <td>{{prescription_getlist?.Appointment_ID}}</td>
                            </tr> -->
                            <tr>
                                <th scope="col"
                                    class="border">Doctor Comments : </th>
                                <td>{{prescription_getlist?.Doctor_Comments}}</td>
                            </tr>
                            <tr>
                                <th scope="col"
                                    class="border">Service Amount : </th>
                                <td>{{prescription_getlist?.service_amount}}</td>
                            </tr>

                            <tr>
                                <th scope="col"
                                    class="border">Date : </th>
                                <td>{{prescription_getlist?.Date}}</td>
                            </tr>

                            <tr>
                                <th scope="col"
                                    class="border">Prescription Type : </th>
                                <td>{{prescription_getlist?.Prescription_type}}</td>
                            </tr>

                            <tr>
                                <th scope="col" class="border">Prescription
                                    Image</th>
                                <td>
                                    <ng-container>
                                        <!-- Check if attachment is an image -->
                                        <ng-container
                                            *ngIf="prescription_getlist.Prescription_img.endsWith('.jpg') || prescription_getlist.Prescription_img.endsWith('.jpeg') || prescription_getlist.Prescription_img.endsWith('.png') || prescription_getlist.Prescription_img.endsWith('.gif')">
                                            <a
                                                href="{{ prescription_getlist.Prescription_img }}"
                                                target="_blank">
                                                <img
                                                    src="{{ prescription_getlist.Prescription_img }}"
                                                    alt="Image">
                                            </a>
                                        </ng-container>
                                        <!-- Check if prescription_getlist.Prescription_img is a PDF -->
                                        <ng-container
                                            *ngIf="prescription_getlist.Prescription_img.endsWith('.pdf')">
                                            <a
                                                href="{{ prescription_getlist.Prescription_img }}"
                                                target="_blank">
                                                <img
                                                    src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                                                    alt="PDF">
                                            </a>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

    <div class="card-box ">
        <div class="row">

            <div>
                <h6><pre
                        style="color: red; font-size: small;"><b>Note :</b> M-Morning, A-Afternoon, N-Night. A.F- After Food , B.F Before Food</pre></h6>
            </div>
            <table
                class="spec-table mb-3">
                <thead>
                    <th>S.No</th>
                    <th>Medicine</th>
                    <th>No of days</th>
                    <th colspan="2">Consumption M. A. N.</th>
                    <th>Intake A.F B.F</th>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let arr of prescription_getlist.Prescription_data; let i = index;">
                        <td>{{ i + 1 }}</td>
                        <td>{{ arr.Tablet_name }}</td>
                        <td>{{ arr.Quantity }}</td>
                        <td colspan="2">
                            <div class="row ml-1">
                                <div class="form-group">
                                    <input type="checkbox"
                                        id="Morning{{i}}"
                                        [checked]="arr.consumption.morning"
                                        disabled>
                                    <label
                                        for="Morning{{i}}">Morning</label>
                                </div>
                                <div class="form-group">
                                    <input type="checkbox"
                                        id="Afternoon{{i}}"
                                        [checked]="arr.consumption.evening"
                                        disabled>
                                    <label
                                        for="Afternoon{{i}}">Afternoon</label>
                                </div>
                                <div class="form-group">
                                    <input type="checkbox"
                                        id="Night{{i}}"
                                        [checked]="arr.consumption.night"
                                        disabled>
                                    <label
                                        for="Night{{i}}">Night</label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row ml-1">
                                <div class="form-group">
                                    <input type="checkbox"
                                        id="After_Food{{i}}"
                                        [checked]="arr.intakeBean.afterfood"
                                        disabled>
                                    <label for="After_Food{{i}}">After
                                        Food</label>
                                </div>
                                <div class="form-group">
                                    <input type="checkbox"
                                        id="Before_Food{{i}}"
                                        [checked]="arr.intakeBean.beforefood"
                                        disabled>
                                    <label for="Before_Food{{i}}">Before
                                        Food</label>
                                </div>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>
    </div>

</section>
