
<section>
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Pet List</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-secondary btn-sm" (click)="back()">Back <i class="fas fa-arrow-right ml-2"></i> </button>

        </div>

    </div>

    <div class="row row-sm">

    <div class="col-lg-12">
        <div class="card custom-card">
        <div class="card-body">
            <div class="card-item">
                <div class="row">
                    <div class="col-md-3">
                        <div class="search-hero">
                            <div class="input-group mb-3">
                               <div class="input-group-prepend">
                                 <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                               </div>
                               <input class="form-control" type="text" name="search2"
                                  [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
                             </div>
                          </div>
                    </div>
                    <div class="col-md-7 text-center">
                        <p-calendar [(ngModel)]="S_Date" [showIcon]="true" inputId="icon" placeholder="Start Date" class="mr-3"></p-calendar>
                        <p-calendar [(ngModel)]="E_Date" [showIcon]="true" inputId="icon" placeholder="End Date" class="mr-3"></p-calendar>
                        <button type="button" class="btn btn-primary" (click)="filter_date()"><i class="fas fa-search"></i> Search</button>
                    </div>
                    <div class="col-md-2 text-right">

                        <button type="button" class="btn btn-primary mr-0" (click)="service_form()"><i class="fas fa-plus-circle"></i> Add</button>
                    </div>
                </div>

            <!-- end search -->
            <div class="doc-table">
                <p-table [value]="pet_list.petdetailsModels | filter:searchQR" styleClass="ui-table-customers" #tt
                    [paginator]="true" [rows]="5" [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [globalFilterFields]="['type']">

                    <ng-template pTemplate="header">
                        <tr class="thhead">
                            <th class="th-1">
                                S.No
                            </th>
                            <th>
                                Pet Photo
                            </th>
                            <th>
                                Pet name
                            </th>
                            <th>
                                Pet Type
                            </th>
                            <th>
                                Pet Breed
                            </th>
                            <th>
                                Pet Gender
                            </th>
                            <th>
                                Pet Color
                            </th>
                            <th>
                                Pet weight
                            </th>
                            <th>
                                Pet age
                            </th>
                            <th>
                                Vaccinated
                            </th>
                            <th>
                                Last Vaccinated date
                            </th>

                            <th>Created at</th>
                            <th style="width: 100px;text-align:center">
                                Action
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr class="tdrow">
                            <td colspan="12">No Record found</td>
                              </tr>
                     </ng-template>
                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                        <tr class="tdrow">
                            <td>{{i+1}}</td>
                            <td>
                                <div class="imgthumcontainer">
                                    <img src="{{item.pet_img}}" alt="">
                                </div>
                            </td>
                            <td>{{item.pet_name}}</td>
                            <td>{{item.pet_type}}</td>
                            <td>{{item.pet_breed}}</td>
                            <td>{{item.pet_gender}}</td>
                            <td>{{item.pet_color}}</td>
                            <td>{{item.pet_weight}} kg</td>
                            <td>{{item.pet_age}} Year</td>
                            <td>{{item.vaccinated}}</td>
                            <td>{{item.last_vaccination_date}}</td>
                            <td>{{item.date_and_time}}</td>

                            <td class="act" style="text-align: center;">
                                <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true"  (click)="edit_details(item)"
                                    pTooltip="Edit"></i>
                                <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete"
                                    (click)="delete_pet(item._id)"></i>
                                <!-- <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true"
                                    pTooltip="View"></i> -->
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>

            </div>
        </div>
    </div>
 </div>
    </div>


<!-- <div class="row">
    <div class="col-md-4">
        <div class="search-hero">
            <input class="form-control" type="text" name="search2" style="font-family: FontAwesome;"
                [(ngModel)]="searchQR" autocomplete="off" placeholder="&#xf002; Search...">
        </div>
    </div>
    <div class="col-md-8 text-right">
        <button type="button" class="btn btn-primary"
            style="color: #FFF;background-color: #fc4652;border: none;margin-right: 10px;"
            (click)="back()">Back</button>
        <button type="button" class="btn btn-primary mr-0" (click)="service_form()">Add</button>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <h5 class="form-lable">Start Date</h5>
        <p-calendar [showIcon]="true" inputId="icon"></p-calendar>
    </div>
    <div class="col-md-4">
        <h5 class="form-lable">End Date</h5>
        <p-calendar [showIcon]="true" inputId="icon"></p-calendar>
    </div>
</div> -->



</section>
