import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';
import { ValidatorService } from '../../../../validator.services';
import { Location } from '@angular/common';
@Component({
  selector: 'app-view-priscriptions',
  templateUrl: './view-priscriptions.component.html',
  styleUrls: ['./view-priscriptions.component.css']
})
export class ViewPriscriptionsComponent implements OnInit {

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  selectedimage: any;
  imagefile: any;
  diagnosis_details: any;
  sub_diagnosis_data: any;
  apoinmentdatas: any;
  prescription: any;
  prescription_getlist: any;

  public handleAddressChange(address: any) {
    this.zoom = 15;
    this.location_lat = Number(address.geometry.location.lat());
    this.location_lng = Number(address.geometry.location.lng());
    this.base_lat = this.location_lat;
    this.base_lng = this.location_lng;
    this.Latitude = this.location_lat;
    this.Longitude = this.location_lng;
    this.address = address.formatted_address;
    console.log(this.address);

  }

  stage1 = false;
  stage2 = false;


  options = {
    types: [],
    componentRestrictions: { country: 'IN' }
  }

  apiUrl = environment.apiUrl;
  imgUrl = environment.imageURL;
  zoom: number = 8;
  base_lat: number = 11.1271;
  base_lng: number = 78.6569;
  location_lat: number = 11.1271;
  location_lng: number = 78.6569;
  Name: any;
  Education: any;
  Specialization: any;
  completion: any;
  Experience: any;
  handled: any;
  Specializationarray: any = [];
  years: any = [];
  address: any;
  tittle: any;
  tittle_idError: any;
  Clinic_Name: any;
  Latitude: any;
  Longitude: any;
  f_date: any;
  T_date: any;
  CName: any;

  thumbnail_image: any;

  selectedimgae: any;
  Exp: any = [
    { "y": "1+ years" },
    { "y": "5+ years" },
    { "y": "10+ years" },
    { "y": "15+ years" },
    { "y": "20+ years" },
    { "y": "25+ years" },
    { "y": "30+ years" },
  ];




  Experiencearray: any = [];
  Completionarray: any = [];
  handledarray: any = [];
  uploadedFiles: any[] = [];
  @ViewChild('imgType', { static: false }) imgType: ElementRef;
  img_path: any = undefined;
  clinic_arr: any = [];
  govt_arr: any = [];
  certificate_arr: any = [];
  photo_arr: any = [];
  Validation: any;
  Email: any;
  Phone: any;
  phone_idError: any;
  Email_id: any;
  Email_idError: any;
  userid: any = undefined;
  type: any;
  detail: any;
  dropdownslist: any;
  consultancy_fees: any;


  ///New Params///

  clinic_number: any = '';
  doctor_id: any = '';
  about_doctor: any = '';
  communication_type: any = '';
  prescription_method: any = 'manual';
  year_list = [];
  city_name = '';


  Doctor_Comments: "";
  PDF_format: "";

  Quantity: '';
  Tablet_name: '';
  evening: Boolean = false;
  morning: Boolean = false;
  night: Boolean = false;
  afterfood: Boolean = false;
  beforefood: Boolean = false;
  Prescription_img: any;
  Prescription_type: "";
  Treatment_Done_by: "";
  diagnosis: any;
  sub_diagnosis: "";
  service_amount: "";


  constructor(
    private toastr: ToastrManager,
    private location: Location,
    private router: Router,
    private ValidatorService: ValidatorService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private http: HttpClient,
    private _api: ApiService,
    private routes: ActivatedRoute,
    public datepipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      this.apoinmentdatas = navigation.extras.state.item;
      localStorage.setItem('apoinmentdatas', JSON.stringify(this.apoinmentdatas));
     console.log("apoinmentdatas",this.apoinmentdatas);
     
    }



    let appointmentData = localStorage.getItem('apoinmentdatas');


    let appointments = JSON.parse(appointmentData); // Parse JSON data

    let priscriptions_Id = {
      Appointment_ID: appointments._id
    }
console.log("priscriptions_Id",priscriptions_Id);

    this._api.prescription_getlist(priscriptions_Id).subscribe(
      (response: any) => {
        console.log(response.Data);
        this.prescription_getlist = response.Data[0];
        console.log(this.prescription_getlist);
      }
    );


  }

  ngOnInit(): void {

  }


  cancel() {
    this.router.navigateByUrl('/admin/Doctor')
  }




  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
  saveInLocal(key, val): void {
    this.storage.set(key, val);
  }
  back() {
    this.location.back();
  }
  getFromLocal(key): any {
    return this.storage.get(key);
  }




  showSuccess(msg) {
    this.toastr.successToastr(msg);
  }

  showError(msg) {
    this.toastr.errorToastr(msg);
  }

  showWarning(msg) {
    this.toastr.warningToastr(msg);
  }
}

