<section style="text-transform:capitalize">
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Pet Care </h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>

    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <input class="form-control" type="text"
                                            name="search2"
                                            [(ngModel)]="searchQR"
                                            autocomplete="off"
                                            placeholder="Search...">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <button (click)="Filter('Completed')"
                                    type="button"
                                    class="btn btn-success btn-sm mr-2"><i
                                        class="fas fa-check-circle"></i>
                                    Completed</button>
                                <button (click)="Filter('Incomplete')"
                                    type="button"
                                    class="btn btn-primary btn-sm mr-2"><i
                                        class="far fa-check-circle"></i>
                                    New</button>
                                <button (click)="Filter('Missed')" type="button"
                                    class="btn btn-danger btn-sm mr-2"><i
                                        class="fas fa-times-circle"></i>
                                    Cancelled</button>
                                <button (click)="refersh()" type="button"
                                    class="btn btn-secondary btn-sm mr-2"><i
                                        class="fas fa-sync-alt"></i>
                                    Refresh</button>
                            </div>

                        </div>

                        <div class="row mt-2">

                            <div class="col-md-12">
                                <div class="col-md-12">
                                    <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy"
                                        [showIcon]="true" inputId="icon"
                                        placeholder="Start Date"
                                        class="mr-2"></p-calendar>
                                    <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy"
                                        [showIcon]="true" inputId="icon"
                                        placeholder="End Date"
                                        class="mr-2"></p-calendar>

                                </div>
                                <div class="row">
                                    <div class="col-md-4 mt-2">

                                        <div class="form-group mb-3 col-md-12">
                                            <h5 for="inputNanme4"
                                                class="form-lable"><b>Pet Type</b>
                                                </h5>
                                            <ng-select
                                                [items]="pet_type_lists"
                                                bindLabel="pet_type_title"
                                                placeholder="Search Pet Types..."
                                                [(ngModel)]="selectedpet_type"
                                                (change)="pet_breed_type()">
                                            </ng-select>

                                        </div>

                                    </div>
                                    <div class="col-md-4 mt-2">
                                        <div class="form-group mb-3 col-md-12">
                                            <label for="inputNanme4"
                                                class="form-label"><b>Pet breed
                                                </b></label>
                                            <ng-select
                                                [items]="pet_breed_types"
                                                bindLabel="pet_breed"
                                                bindValue="pet_breed"
                                                placeholder="Search Pet breed..."
                                                [(ngModel)]="selectedpet_breed">
                                            </ng-select>
                                        </div>

                                    </div>
                                    <div class="col-md-4 mt-2">
                                        <div class="form-group mb-3 col-md-12">
                                            <label for="inputNanme4"
                                                class="form-label"><b>issue type
                                                </b></label>
                                            <ng-select
                                                [items]="health_issue_type"
                                                bindLabel="health_issue_title"
                                                bindValue="health_issue_title"
                                                placeholder="Search Issue Type...."
                                                [(ngModel)]="selectedhealth_issue_type">
                                            </ng-select>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-3 col-md-12">
                                            <label for="inputNanme4"
                                                class="form-label"><b>diagnosis
                                                </b></label>
                                            <ng-select
                                                [items]="diagnosis"
                                                bindLabel="diagnosis"
                                                placeholder="Search diagnosis..."
                                                [(ngModel)]="selecteddiagnosis"
                                                (change)="sub_diagnosis_getlist_byid()">
                                            </ng-select>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-3 col-md-12">
                                            <label for="inputNanme4"
                                                class="form-label"><b>sub
                                                    diagnosis
                                                </b></label>
                                            <ng-select
                                                [items]="sub_diagnosis"
                                                bindLabel="sub_diagnosis"
                                                bindValue="sub_diagnosis"
                                                placeholder="Search Sub diagnosis..."
                                                [(ngModel)]="selectedsub_diagnosis">
                                            </ng-select>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-3 col-md-12">
                                            <label for="inputNanme4"
                                                class="form-label"><b>location</b></label>
                                            <input class="form-control"
                                                type="text"
                                                [(ngModel)]="locationsearch"
                                                autocomplete="off"
                                                (input)="listpettype()"
                                                placeholder="Location Search...">
                                        </div>

                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center">
                                    <button type="button"
                                        class="btn btn-primary m-3 "
                                        (click)="filterData()"><i
                                            class="fas fa-search"></i>
                                        Search</button>
                                    <button type="button"
                                        class="btn btn-primary m-3 "
                                        (click)="clearfilter()">
                                        Clear</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="doc-table table-responsive">
                            <div class="ui-helper-clearfix"
                                style="text-align: left">
                                <button type="button" pButton
                                    icon="pi pi-file-o" iconPos="left"
                                    label="EXCEL"
                                    (click)="exportAsXLSX()"
                                    style="margin-right: 0.5em; margin-bottom: 10px;"></button>
                                <!--  <button type="button" pButton icon="fas fa-file-o" iconPos="left" label="All Data" (click)="dt.exportCSV()" style="float:left"></button>          -->
                            </div>
                            <p-table
                                [value]="appointment_list | filter:searchQR"
                                styleClass="ui-table-customers" #tt
                                [paginator]="true" [rows]="15" [rowHover]="true"
                                [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']"
                                class="table-responsive">
                                <!-- <ng-template pTemplate="caption">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="50">
                <h5 style="color:#fff;">Entry Type List</h5>
            </div>
            <div fxFlex="50">
                <div class="ui-table-globalfilter-container">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Entry Type">
                </div>
            </div>

        </div>

    </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th style="width: 170px !important;">
                                            Client name
                                        </th>
                                        <th style="width: 150px !important;">Client phone </th>
                                        <th>Pet Type</th>
                                        <th>Pet Breed</th>
                                        <th>Pet Name</th>
                                        <th>Issue Type</th>
                                        <th>Doctor Name</th>
                                        <!-- <th>Diagnosis</th>
                                        <th>Sub Diagnosis</th> -->
                                        <th style="width: 200px;" >Appointment ID</th>
                                        <th style="width: 200px;">Appointment Date & Time</th>
                                        <th style="width: 200px;">Appointment Type </th>
                                        <th >Location</th>
                                        <th >Status</th>
                                        <th>Booking Date & Time</th>
                                        <th>
                                            Consultation type
                                        </th>
                                        <!-- <th>Device type</th> -->
                                        <!-- <th>Created at</th> -->
                                        <th>Amount</th>
                                        <th
                                            style="width: 110px;text-align:center">
                                            Action
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item
                                    let-i="rowIndex">
                                    <tr class="tdrow" (click)="view_details(item)"
                                        style="text-transform:none"
                                       >
                                        <td>{{i+1}}</td>
                                        <td>{{item.user_id?.first_name}}
                                            {{item.user_id?.last_name}}</td>
                                        <td>{{item.user_id?.user_phone}}</td>
                                        <td>{{item.family_id?.pet_type}}</td>
                                        <td>{{ item.family_id?.pet_breed !== "" ?
                                            item.family_id?.pet_breed : "-"
                                            }}</td>
                                        <td>{{item.family_id?.pet_name}}</td>
                                        <td>{{item.health_issue_title}}</td>
                                        <td>{{item.doc_business_info[0].dr_name}}</td>
                                        <!-- <td>{{ item.diagnosis !== "" ?
                                            item.diagnosis : "-" }}</td>
                                        <td>{{ item.sub_diagnosis !== "" ?
                                            item.sub_diagnosis : "-" }}</td> -->
                                        <td >{{ item.appointment_UID
                                            !== "" ?
                                            item.appointment_UID : "-" }}</td>
                                        <td>{{item.booking_date_time}}</td>
                                        <td>{{item.appointment_types}}</td>
                                        <td >{{item.doc_business_info
                                            !== [] ?
                                            item.doc_business_info[0].clinic_loc
                                            : "-"}}</td>
                                            <td > <span class="badge" 
                                                [ngClass]="{
                                                  'badge-info': item.appoinment_status === 'Incomplete',
                                                  'badge-success': item.appoinment_status === 'Completed',
                                                  'badge-danger': item.appoinment_status === 'Missed'
                                                }">
                                            {{ getStatusLabel(item.appoinment_status) }}
                                          </span></td>
                                        <td>{{item.booking_date_time}}</td>
                                        <td>{{item.communication_type}}</td>
                                        <!-- <td>{{item.mobile_type}}</td> -->
                                        <!-- <td>{{item.booking_date_time}}</td> -->
                                        <td>{{item.amount}}</td>
                                        <td class="act text-center">
                                            <!-- <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" pTooltip="Edit"></i>
                    <i style="color: #4CAF50;" class="fa  fa-clock-o" aria-hidden="true" pTooltip="Reschedule"></i>
                    <i style="color: #fd2c3a;" class="fa fa-ban" aria-hidden="true" pTooltip="Disable"></i> -->
                                            <i style="color: #fd2c3a;"
                                                class="fa fa-trash"
                                                aria-hidden="true"
                                                (click)="Delete(item._id)"
                                                pTooltip="Delete"></i>
                                            <i style="color: #31ccfc;"
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                (click)="view_details(item)"
                                                pTooltip="View Details"></i>

                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" >
                                    <tr class="full text-center" *ngIf="isLoading" >
                                        <td colspan="19" >
                                          <div class="custom-flex">
                                          <img class="img-fluid loader-logo" src="assets/images/dogloader.gif" alt="loader" > <div class="fs">Loading....</div> </div>
                                        </td>   
                                      </tr>
                                    <tr class="full text-center" *ngIf="appointment_list?.length==0 && !isLoading">
                                        <td colspan="19" >
                                          <div class="custom-flex">
                                                <img class="img-fluid loader-logo" src="assets/images/nodataimage.png" alt="Logo" >
                                               <div class="fs">No records found</div> </div>
                                        </td>
                                      </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" >
                                    <tr  *ngIf="appointment_list?.length !=0 && !isLoading">
                                        <td colspan="19"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</section>