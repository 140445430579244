<mat-drawer-container class="example-container" [ngClass]="{'menuslider' : menu_slider}">
  <mat-drawer class="side" mode="side" opened>
      <mat-nav-list>
          <mat-list-item (click)="menuslide()">
              <mat-icon class="menuBar"> notes</mat-icon>
          </mat-list-item>
          <mat-list-item routerLink="dashboard" routerLinkActive="active">
              <mat-icon  mat-list-icon><i class="fab fa-slideshare" aria-hidden="true"
                      ></i></mat-icon>
              <div fxFlex="10"></div>
              <div class="sidenav-item">
                  <h5 class="lead">Dashboard</h5>
              </div>
          </mat-list-item>

          <mat-list-item routerLinkActive="active" (click)="expand1 = !expand1">
              <mat-icon  mat-list-icon>
                  <i class="fab fa-android" aria-hidden="true"
                      ></i></mat-icon>
              <div fxFlex="10"></div>
              <div class="sidenav-item">
                  <h5 class="lead">App Features</h5>
              </div>
              <div class="sb-sidenav-collapse-arrow">
                  <i *ngIf="!expand1" class="fas fa-chevron-down" aria-hidden="true"></i>
                  <i *ngIf="expand1" class="fas fa-chevron-up" aria-hidden="true"></i>
              </div>
          </mat-list-item>
          <mat-nav-list style="background: #2295C4; padding-left: 5px;" *ngIf="expand1">
              <div class="sub-menu">

                  <mat-list-item routerLinkActive="active" (click)="expand2 = !expand2">
                      <mat-icon  mat-list-icon>
                          <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                          <img class="sidebar-icon" src="../../assets/images/sidebar-menu/master.png" alt="">  
                        </mat-icon>
                      <div fxFlex="10"></div>
                      <div class="sidenav-item">
                          <h5 class="lead">Master</h5>
                      </div>
                      <div class="sb-sidenav-collapse-arrow">
                          <i *ngIf="!expand2" class="fas fa-chevron-down" aria-hidden="true"></i>
                          <i *ngIf="expand2" class="fas fa-chevron-up" aria-hidden="true"></i>
                      </div>
                  </mat-list-item>
                  <mat-nav-list style="background: #2295C4;" *ngIf="expand2">
                      <div class="sub-menu">
                          <!-- <mat-list-item style="padding-left: 5px;" routerLink="user_type" routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                  
                                  <img class="sidebar-icon" src="../../assets/images/sidebar-menu/user-type-managment.png" alt="">
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">User type management</h5>
                              </div>
                          </mat-list-item> -->
                          <!-- <mat-list-item style="padding-left: 5px;" routerLink="demo_page" routerLinkActive="active">
                              <mat-icon  mat-list-icon><i class="fas fa-arrow-circle-right"
                                      aria-hidden="true"
                                      ></i>
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Demo Page</h5>
                              </div>
                          </mat-list-item> -->
                          <mat-list-item style="padding-left: 5px;" routerLink="healthissue" routerLinkActive="active">
                            <mat-icon  mat-list-icon>
                                <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                                <img class="sidebar-icon" src="../../assets/images/sidebar-menu/health-issue.png" alt="">
                            </mat-icon>
                            <div fxFlex="10"></div>
                            <div class="sidenav-item">
                                <h5 class="lead">Health Issue</h5>
                            </div>
                        </mat-list-item>
                          <mat-list-item style="padding-left: 5px;" routerLink="doc_specialization"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                  <!-- <i class="fas fa-arrow-circle-right"aria-hidden="true"></i> -->
                                  <img class="sidebar-icon" src="../../assets/images/sidebar-menu/doctor specialaization.png" alt="">
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Doctor  Specialization</h5>
                              </div>
                          </mat-list-item>
                          <mat-list-item style="padding-left: 5px;" routerLink="master/sp/specialization"
                          routerLinkActive="active">
                          <mat-icon  mat-list-icon>
                            <img class="sidebar-icon" src="../../assets/images/sidebar-menu/service-specialization.png" alt="">
                            <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true" ></i> -->
                          </mat-icon>
                          <div fxFlex="10"></div>
                          <div class="sidenav-item">
                              <h5 class="lead">Service Specialization</h5>
                          </div>
                      </mat-list-item>
                          <!-- <mat-list-item style="padding-left: 5px;" routerLink="Pet_handle" routerLinkActive="active">
                              <mat-icon  mat-list-icon><i class="fas fa-arrow-circle-right"
                                      aria-hidden="true"
                                      ></i>
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Doctor-Pet Handled</h5>
                              </div>
                          </mat-list-item> -->
                          <mat-list-item style="padding-left: 5px;" routerLink="Pet_type" routerLinkActive="active">
                              <mat-icon  mat-list-icon><i class="fas fa-arrow-circle-right"
                                      aria-hidden="true"
                                      ></i>
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Pet Type Management</h5>
                              </div>
                          </mat-list-item>
                          <mat-list-item style="padding-left: 5px;" routerLink="Pet_Breed_Type"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon><i class="fas fa-arrow-circle-right"
                                      aria-hidden="true"
                                      ></i>
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Pet Breed Management</h5>
                              </div>
                          </mat-list-item>


                          <mat-list-item style="padding-left: 5px;" routerLink="master/banner/homebanner"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                <img class="sidebar-icon" src="../../assets/images/sidebar-menu/home-page-banner.png" alt="">  
                                <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Home Page Banner</h5>
                              </div>
                          </mat-list-item>

                          <mat-list-item style="padding-left: 5px;" routerLink="master/banner/servicebanner"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                  <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                                  <img class="sidebar-icon" src="../../assets/images/sidebar-menu/service-banner.png" alt="">
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Service Banner</h5>
                              </div>
                          </mat-list-item>


                          <mat-list-item style="padding-left: 5px;" routerLink="master/banner/doctorbanner"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                  <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                                  <img class="sidebar-icon" src="../../assets/images/sidebar-menu/doctor-banner.png" alt="">
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Doctor Banner</h5>
                              </div>
                          </mat-list-item>

                          <mat-list-item style="padding-left: 5px;" routerLink="master/banner/minibanner"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                <img class="sidebar-icon" src="../../assets/images/sidebar-menu/mini-banner.png" alt="">
                                <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Mini Home Page Banner</h5>
                              </div>
                          </mat-list-item>

                          

                          <mat-list-item style="padding-left: 5px;" routerLink="master/banner/ecombanner"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                  <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true" ></i> -->
                                  <img class="sidebar-icon" src="../../assets/images/sidebar-menu/e-commerace-banner.png" alt="">
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">E-Commerce Banner</h5>
                              </div>
                          </mat-list-item>


                          <mat-list-item style="padding-left: 5px;" routerLink="master/banner/splashscreen"
                              routerLinkActive="active">
                              <mat-icon  mat-list-icon>
                                  <!-- <i class="fas fa-arrow-circle-right" aria-hidden="true"></i> -->
                                  <img class="sidebar-icon" src="../../assets/images/sidebar-menu/splash-screen-banner.png" alt="">
                              </mat-icon>
                              <div fxFlex="10"></div>
                              <div class="sidenav-item">
                                  <h5 class="lead">Splash Screen Banner</h5>
                              </div>
                          </mat-list-item>

                          <mat-list-item style="padding-left: 5px;" routerLink="Diagnosis"
                          routerLinkActive="active">
                          <mat-icon  mat-list-icon>
                              <!-- <i class="fas fa-arrow-circle-right"></i> -->
                              <img class="sidebar-icon" src="../../assets/images/sidebar-menu/diagnosis.png" alt="">
                          </mat-icon>
                          <div fxFlex="10"></div>
                          <div class="sidenav-item">
                              <h5 class="lead">Diagnosis
                              </h5>
                          </div>
                      </mat-list-item>

                      <mat-list-item style="padding-left: 5px;" routerLink="Sub_diagnosis"
                      routerLinkActive="active">
                      <mat-icon  mat-list-icon>
                          <!-- <i class="fas fa-arrow-circle-right"></i> -->
                          <img class="sidebar-icon" src="../../assets/images/sidebar-menu/sub-diagnosis.png" alt="">
                      </mat-icon>
                      <div fxFlex="10"></div>
                      <div class="sidenav-item">
                          <h5 class="lead">Sub Diagnosis
                          </h5>
                      </div>
                  </mat-list-item>

                  <mat-list-item style="padding-left: 5px;" routerLink="Category_Management"
                  routerLinkActive="active">
                  <mat-icon  mat-list-icon>
                      <!-- <i class="fas fa-arrow-circle-right"></i> -->
<img class="sidebar-icon" src="../../assets/images/sidebar-menu/category.png" alt="">
                  </mat-icon>
                  <div fxFlex="10"></div>
                  <div class="sidenav-item">
                      <h5 class="lead">Category Management
                      </h5>
                  </div>
                  </mat-list-item>

                  <mat-list-item style="padding-left: 5px;" routerLink="Service_Type"
                  routerLinkActive="active">
                  <mat-icon  mat-list-icon>
                      <!-- <i class="fas fa-arrow-circle-right"></i> -->
                      <img class="sidebar-icon" src="../../assets/images/sidebar-menu/service-type.png" alt="">

                  </mat-icon>
                  <div fxFlex="10"></div>
                  <div class="sidenav-item">
                      <h5 class="lead">Service Type
                      </h5>
                  </div>
                  </mat-list-item>

                      </div>
                  </mat-nav-list>










              </div>
          </mat-nav-list>



           <mat-list-item routerLink="Customer_Management" routerLinkActive="active">
                <mat-icon  mat-list-icon><i class="fas fa-users" aria-hidden="true"
                        ></i></mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Customer Management</h5>
                </div>
               </mat-list-item>


               <mat-list-item routerLinkActive="active" (click)="expand12 = !expand12">
                <mat-icon  mat-list-icon>
                    <!-- <i class="fa fa-users" aria-hidden="true" ></i> -->
                    <img class="sidebar-icon" src="../../assets/images/sidebar-menu/health-z-partners.png" alt="">
                </mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Petfolio Partners</h5>
                </div>
                <div class="sb-sidenav-collapse-arrow">
                    <i *ngIf="!expand12" class="fas fa-chevron-down" aria-hidden="true"></i>
                    <i *ngIf="expand12" class="fas fa-chevron-up" aria-hidden="true"></i>
                </div>
                  </mat-list-item>
                  <mat-nav-list style="background: #2295C4;padding-left: 5px;" *ngIf="expand12">
                    <div class="sub-menu">
                      <mat-list-item style="padding-left: 5px;" routerLink="doctor/doctor_list"
                          routerLinkActive="active">
                          <mat-icon  mat-list-icon>
                            <img class="sidebar-icon" src="../../assets/images/sidebar-menu/doctor.png" alt="">
                            <!-- <i class="fas fa-user-md" aria-hidden="true" ></i> -->
                          </mat-icon>
                          <div fxFlex="10"></div>
                          <div class="sidenav-item">
                              <h5 class="lead">Doctors</h5>
                          </div>
                      </mat-list-item>

                      <mat-list-item style="padding-left: 5px;" routerLink="Vendor_Management"
                      routerLinkActive="active">
                      <mat-icon  mat-list-icon>
                         
                              <img class="sidebar-icon" src="../../assets/images/sidebar-menu/vendor.png" alt="">
                              <!--  <i class="fas fa-shopping-bag" aria-hidden="true" ></i>
                                  <i class="fas fa-shopping-bag"></i> -->
                      </mat-icon>
                      <div fxFlex="10"></div>
                      <div class="sidenav-item">
                          <h5 class="lead">Vendors</h5>
                      </div>
                  </mat-list-item>

                  <mat-list-item style="padding-left: 5px;" routerLink="serviceprovider_details"
                  routerLinkActive="active">
                  <mat-icon  mat-list-icon>
                      <!-- <i class="fas fa-user-tie" aria-hidden="true"></i> -->
                      <img class="sidebar-icon" src="../../assets/images/sidebar-menu/service-provider.png" alt="">
                  </mat-icon>
                  <div fxFlex="10"></div>
                  <div class="sidenav-item">
                      <h5 class="lead">Service Providers</h5>
                  </div>
              </mat-list-item>

                  </div>
                  </mat-nav-list>




          <mat-list-item routerLinkActive="active" (click)="expand4 = !expand4">
            <mat-icon  mat-list-icon>
                <!-- <i class="fa fa-users" aria-hidden="true" ></i> -->
                <img class="sidebar-icon" src="../../assets/images/sidebar-menu/product-management.png" alt="">
            </mat-icon>
            <div fxFlex="10"></div>
            <div class="sidenav-item">
                <h5 class="lead">Product Management</h5>
            </div>
            <div class="sb-sidenav-collapse-arrow">
                <i *ngIf="!expand12" class="fas fa-chevron-down" aria-hidden="true"></i>
                <i *ngIf="expand12" class="fas fa-chevron-up" aria-hidden="true"></i>
            </div>
              </mat-list-item>
              <mat-nav-list style="background: #2295C4;padding-left: 5px;" *ngIf="expand4">
                <div class="sub-menu">
                  <mat-list-item style="padding-left: 5px;" routerLink="vendor/vendor_add_productdetail"
                      routerLinkActive="active">
                      <mat-icon  mat-list-icon>
                          <!-- <i class="fas fa-user-md" aria-hidden="true" ></i> -->
                          <img class="sidebar-icon" src="../../assets/images/sidebar-menu/add-product.png" alt="">
                      </mat-icon>
                      <div fxFlex="10"></div>
                      <div class="sidenav-item">
                          <h5 class="lead">Add New Product</h5>
                      </div>
                  </mat-list-item>

                  <mat-list-item style="padding-left: 5px;" routerLink="vendor/vendor_productdetail"
                  routerLinkActive="active">
                  <mat-icon  mat-list-icon>
                      <!-- <i class="fas fa-shopping-bag" aria-hidden="true"></i>      -->
                      <img class="sidebar-icon" src="../../assets/images/sidebar-menu/product-details.png" alt="">
                                 </mat-icon>
                  <div fxFlex="10"></div>
                  <div class="sidenav-item">
                      <h5 class="lead">Product Details</h5>
                  </div>
              </mat-list-item>

              </div>
              </mat-nav-list>
<!--
              <mat-list-item routerLink="Add_Product" routerLinkActive="active">
                <mat-icon  mat-list-icon><i class="fas fa-shopping-cart" aria-hidden="true"
                        ></i></mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Product Management</h5>
                </div>
               </mat-list-item> -->


              <mat-list-item routerLinkActive="active" (click)="expand3 = !expand3">
                <mat-icon  mat-list-icon>
                    <!-- <i class="fas fa-calendar" aria-hidden="true"></i> -->
                    <img class="sidebar-icon" src="../../assets/images/sidebar-menu/appointments.png" alt="">  
                </mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Appointments</h5>
                </div>
                <div class="sb-sidenav-collapse-arrow">
                    <i *ngIf="!expand3" class="fas fa-chevron-down" aria-hidden="true"></i>
                    <i *ngIf="expand3" class="fas fa-chevron-up" aria-hidden="true"></i>
                </div>
            </mat-list-item>


            <mat-nav-list style="background: #2295C4;padding-left: 5px;" *ngIf="expand3">
              <div class="sub-menu">
                  <mat-list-item style="padding-left: 5px;" routerLink="doctor_appointment"
                      routerLinkActive="active">
                      <mat-icon  mat-list-icon>
                        <img class="sidebar-icon" src="../../assets/images/sidebar-menu/doctor-appointments.png" alt="">  
                          <!-- <i class="fas fa-user-nurse" aria-hidden="true" ></i> -->
                      </mat-icon>
                      <div fxFlex="10"></div>
                      <div class="sidenav-item">
                          <h5 class="lead">Pet Care</h5>
                      </div>
                  </mat-list-item>
                  <mat-list-item style="padding-left: 5px;" routerLink="Service_appointment" routerLinkActive="active">
                      <mat-icon  mat-list-icon>
                        <img class="sidebar-icon" src="../../assets/images/sidebar-menu/service-appointments.png" alt=""> 
                          <!-- <i class="fas fa-dog" aria-hidden="true" ></i> -->
                    </mat-icon>
                      <div fxFlex="10"></div>
                      <div class="sidenav-item">
                          <h5 class="lead">Pet Service</h5>
                      </div>
                  </mat-list-item>
              </div>
          </mat-nav-list>


          <!-- <mat-list-item routerLink="order_managment" routerLinkActive="active">
            <mat-icon  mat-list-icon><i class="fas fa-list" aria-hidden="true"
                    ></i></mat-icon>
            <div fxFlex="10"></div>
            <div class="sidenav-item">
                <h5 class="lead">Order Management</h5>
            </div>
           </mat-list-item> -->

           <!-- <mat-list-item routerLink="notification" routerLinkActive="active">
            <mat-icon  mat-list-icon><i class="fas fa-bell" aria-hidden="true"
                    ></i></mat-icon>
            <div fxFlex="10"></div>
            <div class="sidenav-item">
                <h5 class="lead">Notification Management</h5>
            </div>
           </mat-list-item> -->

           <!-- <mat-list-item routerLink="payment_management" routerLinkActive="active">
            <mat-icon  mat-list-icon><i class="fas fa-credit-card" aria-hidden="true"
                    ></i></mat-icon>
            <div fxFlex="10"></div>
            <div class="sidenav-item">
                <h5 class="lead">Payment Management</h5>
            </div>
           </mat-list-item> -->


      </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
      <section class="container-fluid">
          <router-outlet></router-outlet>
      </section>

  </mat-drawer-content>
</mat-drawer-container>
