<section>
    <div class="page-header ">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Health Issue</h2>
            <!-- <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page"> Details</li>
          </ol> -->
        </div>

    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-4">
                                <h5 class="form-lable">Health Issue Type</h5>
                                <input type="text" pInputText name="health_issue_title" [(ngModel)]="health_issue_title"
                                    placeholder="Enter Health Issue">
                            </div>
                            <div class="col-md-4">
                                <h5 class="form-lable">Health Issue Type Icon</h5>
                                <input type="file" #imgType (change)="fileupload($event)" id="imgfile"
                                    accept=".png, .jpg, .jpeg" class="form-control" id="city"
                                    placeholder="User type icon">
                                <!-- <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the
                                    user type icon with 100 * 100 (Max 100 kB)</div> -->
                                <div class="imgthumcontainer mt-1"> <img src="{{health_issue_img}}" style="width: 15%;">
                                </div>
                            </div>
                            <div class="col-md-4 mt-1">
                                <button *ngIf="update_button == true" [disabled]="addEditBtn" type="button" class="btn btn-primary mt-4"
                                    (click)="Insert_pet_type_details()"><i class="fas fa-plus-circle"></i> Add</button>
                                <button *ngIf="update_button == false" [disabled]="addEditBtn" type="button" class="btn btn-primary mt-4"
                                    (click)="Edit_pet_type_details()"> <i class="fas fa-edit"></i> Update</button>
                                <button *ngIf="update_button == false" type="button" class="btn btn-danger mt-4"
                                    (click)="cancel()">
                                    Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">

                        <div class="row">
                            <div class="col-md-4">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <span class="clearable">
                                            <input class="form-control" type="text" name="search2"
                                                [(ngModel)]="searchQR" autocomplete="off" placeholder="Search..."
                                                (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                (keypress)='research()' (blur)='research1()'>
                                            <i *ngIf="shremove" class="clearable__clear"
                                                (click)="tt.filterGlobal($event.target.value, 'contains');remove()">&times;</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-center">
                                <p-calendar [(ngModel)]="S_Date" [showIcon]="true" inputId="icon"
                                    placeholder="Start Date" class="mr-3" [readonlyInput]="true"></p-calendar>
                                <p-calendar [(ngModel)]="E_Date" [showIcon]="true" inputId="icon" placeholder="End Date"
                                    class="mr-3" [readonlyInput]="true"></p-calendar>
                                <button type="button" class="btn btn-primary" (click)="filter_date()"><i
                                        class="fas fa-search"></i> Search</button>
                                <button type="button" class="btn btn-secondary" (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                            <!-- <div class="col-md-3 text-right">
                                <button type="button" class="btn btn-secondary" (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i> Refresh</button>
                            </div> -->
                        </div>



                        <!-- search -->

                        <div class="table-responsive">
                            <p-table #tt [value]="rows" styleClass="ui-table-customers" [paginator]="true" [rows]="15"
                                [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['health_issue_title']">
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th>
                                            Health Issue Image
                                        </th>
                                        <th>
                                            Health Issue Type
                                        </th>
                                        <th>Created By</th>
                                        <!-- <th>Modified by</th> -->
                                        <th>Created At</th>
                                        <!-- <th>Modified at</th> -->
                                        <th style="width: 163px;text-align:center">
                                            Action
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item let-i="rowIndex">
                                    <!-- <ng-container *ngFor="let item of user_type_list ; let i = index;"> -->
                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <img _ngcontent-dah-c159="" src="{{item.health_issue_img}}"
                                                alt="Denim Jeans" style="width: 20%;">
                                        </td>
                                        <td>{{item.health_issue_title}}</td>
                                        <td>
                                            Admin
                                        </td>
                                        <td>
                                            {{item.createdAt | date: 'short'}}
                                        </td>
                                        <td class="act text-center">
                                            <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true"
                                                (click)="Editcompanydetailsdata(item)" pTooltip="Edit"></i>
                                            <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                pTooltip="Delete" (click)="Deletecompanydetails(item._id)"></i>
                                            <!-- <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true" pTooltip="View"></i> -->
                                        </td>
                                    </tr>
                                    <!-- </ng-container> -->
                                </ng-template>
                                <ng-template pTemplate="footer" >
                                    <tr class="full text-center" *ngIf="isLoading" >
                                        <td colspan="6" >
                                          <div class="custom-flex">
                                          <div class="spinner-grow spinner-grow-sm bg-info"  role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div> <div class="fs">Loading....</div> </div>
                                        </td>   
                                      </tr>
                                    <tr class="full text-center"  *ngIf="rows?.length==0 && !isLoading">
                                        <td colspan="6" >
                                          No Records Found
                                        </td>
                                      </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" >
                                    <tr  *ngIf="rows?.length !=0 && !isLoading">
                                        <td colspan="6"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                    </tr>
                                </ng-template>
                            
                            </p-table>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</section>