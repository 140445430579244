<section>
    <div class="page-header ">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Service Specialization</h2>
            <!-- <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page"> Details</li>
          </ol> -->
        </div>


    </div>


    <ng-template #addedDialog>
        <div class="text-center">
            <i class="fas fa-check-circle text-success fa-5x"></i>
            <p class="lead mt-3 mb-3 font-weight-bold">Added Successfully</p>
            <button class="btn btn-primary btn-sm" style="height: inherit;" matDialogClose>Close</button>
        </div>
    </ng-template>

    <ng-template #updateDialog>
        <div class="text-center">
            <i class="far fa-check-circle text-success fa-5x"></i>
            <p class="lead mt-3 mb-3 font-weight-bold">Updated Successfully</p>
            <button class="btn btn-primary btn-sm" style="height: inherit;" matDialogClose>Close</button>
        </div>
    </ng-template>


    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body" style="padding-top:10px; padding-bottom:5px;">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-4">
                                <h5 class="form-lable">Specialization:</h5>
                                <input type="text" pInputText [(ngModel)]="specialzation" name="specialzation"
                                    placeholder="Enter Specialization">
                            </div>
                            <!-- <div class="col-md-4">
            <h5 class="form-lable">Pet Icon:</h5>
            <input type="file" pInputText [(ngModel)]="value1" placeholder="Enter Pet Type">
        </div> -->

                            <div class="col-md-4 mt-2">
                                <button *ngIf="update_button == true" type="button" class="btn btn-primary mt-4"
                                    (click)="Insert_pet_type_details()"><i class="fas fa-plus-circle"></i> Add</button>
                                <button *ngIf="update_button == false" type="button" class="btn btn-primary mt-4"
                                    (click)="Edit_pet_type_details()"><i class="fas fa-pen"></i> Update</button>
                                <button *ngIf="update_button == false" type="button" class="btn btn-danger mt-4"
                                    (click)="cancel()">
                                    Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row row-sm">

        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <span class="clearable">
                                            <input class="form-control" type="text" name="search2"
                                                (input)="tt.filterGlobal($event.target.value, 'contains')"
                                                [(ngModel)]="searchQR" autocomplete="off" placeholder="Search..."
                                                (keypress)='research()' (blur)='research1()'>
                                            <i *ngIf="shremove" class="clearable__clear"
                                                (click)="tt.filterGlobal('', 'contains');remove()">&times;</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-lg-right text-sm-left">
                                <p-calendar placeholder="Start Date" [(ngModel)]="S_Date" [showIcon]="true"
                                    inputId="icon" class="mr-2" [readonlyInput]="true"></p-calendar>
                                <p-calendar placeholder="End Date" [(ngModel)]="E_Date" [showIcon]="true" inputId="icon"
                                    class="mr-3" [readonlyInput]="true"></p-calendar>
                                <button type="button" class="btn btn-primary mr-2" (click)="filter_date()"><i
                                        class="fas fa-search"></i> Search</button>
                                <button type="button" class="btn btn-secondary" (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i> Refresh</button>
                            </div>

                        </div>



                        <div class="table-responsive">
                            <p-table #tt [value]="rows" styleClass="ui-table-customers"  [paginator]="true"
                                [rows]="15" [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['specialzation']">
                                <!-- <ng-template pTemplate="caption">
      <div fxLayout="row" fxLayoutAlign="space-around center">
          <div fxFlex="50">
              <h5 style="color:#fff;">Entry Type List</h5>
          </div>
          <div fxFlex="50">
              <div class="ui-table-globalfilter-container">
                  <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                      placeholder="Entry Type">
              </div>
          </div>

      </div>

  </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th>
                                            Specialization
                                        </th>
                                        <!-- <th>
            Icon
          </th> -->
                                        <th>Created By</th>
                                        <th>Created At</th>
                                        <th style="width: 163px;text-align:center">
                                            Action
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item let-i="rowIndex">
                                    <tr class="tdrow">
                                        <td>{{i+1}}</td>
                                        <td>{{item.specialzation}}</td>
                                        <td>
                                            Admin
                                        </td>
                                        <td>
                                            {{item.createdAt | date: 'short'}}
                                        </td>
                                        <td class="act text-center">
                                            <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true"
                                                (click)="Editcompanydetailsdata(item)" pTooltip="Edit"></i>
                                            <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                pTooltip="Delete" (click)="Deletecompanydetails(item._id)"></i>
                                            <!-- <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true" pTooltip="View"></i> -->
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" >
                                    <tr class="full text-center" *ngIf="isLoading" >
                                        <td colspan="5" >
                                          <div class="custom-flex">
                                          <div class="spinner-grow spinner-grow-sm bg-info"  role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div> <div class="fs">Loading....</div> </div>
                                        </td>   
                                      </tr>
                                    <tr class="full text-center" *ngIf="rows?.length==0 && !isLoading">
                                        <td colspan="5" >
                                          No Records Found
                                        </td>
                                      </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" >
                                    <tr  *ngIf="rows?.length !=0 && !isLoading">
                                        <td colspan="5"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="row">
  <div class="col-md-4">
      <div class="search-hero">
          <div class="input-group mb-3">
             <div class="input-group-prepend">
               <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
             </div>
             <input class="form-control" type="text" name="search2"
         [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
           </div>
 </div>
  </div>
</div>
<div class="row">
  <div class="col-md-4">
      <h5 class="form-lable">Start Date</h5>
      <p-calendar [(ngModel)]="S_Date" [showIcon]="true" inputId="icon"></p-calendar>
  </div>
  <div class="col-md-4">
      <h5 class="form-lable">End Date</h5>
      <p-calendar [(ngModel)]="E_Date" [showIcon]="true" inputId="icon"></p-calendar>
  </div>
  <div class="col-md-4">
      <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
          (click)="filter_date()">Search</button>
          <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
          (click)="refersh()">Refresh</button>
  </div>
</div> -->




</section>