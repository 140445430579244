

<header id="header" class="header fixed-top bg-light"
    style="background: white !important;">
    <div
        class="container-fluid container-xl d-flex align-items-center justify-content-between">

        <a href="index.html" class="logo d-flex align-items-center">
            <!-- <img src="../../assets/webassets/img/logo 2.png" alt>
            <span>Petfolio</span> -->
            <img src="assets/images/logo/logo_pet12.png" alt >
            <span>Petfolio</span>
        </a>
       
        <nav id="navbar" class="navbar" >
            <ul>
                <li><a class="nav-link scrollto active"
                        href="#hero">Home</a></li>
                <li><a class="nav-link scrollto"
                        href="#about">About</a></li>
                <li><a class="nav-link scrollto"
                        href="#Features">Features</a></li>
                <li><a class="nav-link scrollto"
                        href="#Services">Services </a></li>

                <li><button class="getstarted scrollto" href="#about"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg">Become a
                        Partner</button></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"  data-toggle="modal1" data-target=".bd-example-modal-lg"></i>
        </nav><!-- .navbar -->

    </div>
</header><!-- End Header -->


<!-- ======= Hero Section ======= -->

<!-- End Hero -->


<main id="main">
    <section id="hero" class="hero">
        <div class="container ">
            <div class="grid-container ">
    
                <div class="grid-item">
                    <h1>Pet Care Excellence Thousands more fun!</h1>
                    <h2 data-aos-delay="400">Discover a world where pets are
                        cherished, cared for, and celebrated. At Petfolio, we're
                        dedicated to providing top-tier services, products, and
                        resources to ensure your furry friends live their best
                        lives.</h2>
                    <div data-aos-delay="600">
                        <div class="text-center text-lg-start mt-2">
                            <div class="logo d-flex align-items-center gx-2 mt-5">
                                <a href="index.html" class="me-3">
                                    <img
                                        src="../../assets/webassets/img/Google Play (2).png"
                                        width="200px" class="img-fluid" alt>
                                </a>
                                <a href="index.html">
                                    <img
                                        src="../../assets/webassets/img/apple (1).png"
                                        width="200px" class="img-fluid" alt>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-item">
                    <img src="../../assets/webassets/img/Group 6872.png"
                        width="100%"
                        class="img-fluid" alt>
                </div>
            </div>
        </div>
    </section>
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
        <div class="container">
            <div class="text-center">
                <h6>Who Are We?</h6>
                <h4>Your Ultimate Pet Care Companion</h4>
            </div>
            <div class="row gx-0">
                <div class="grid-container">
                    <div class=" d-flex align-items-center grid-item">
                        <img src="../../assets/webassets/img/Group 6870.png"
                            width="450px" class="img-fluid" alt>
                    </div>
                    <div
                        class="d-flex flex-column justify-content-center ">
                        <div class="content">
                            <h2>Empowering Pet Happiness, Every Paw Step</h2>
                            <p>
                                At Petfolio, we are passionate about the
                                well-being of your furry companions. Founded by
                                dedicated pet lovers, our mission is to provide
                                a comprehensive platform that caters to all
                                aspects of pet care and ownership. We understand
                                the special bond you share with your pets, and
                                our team is committed to offering reliable
                                resources, expert advice, and top-quality
                                products to support you in providing the best
                                possible care for your beloved animals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="Features" class="about">
        <div class="container">
            <div class="text-center">
                <h6>What We Do?</h6>
                <h4>Your Pet Care Companion App</h4>
            </div>
            <div class="row gx-0">
                <div class="grid-container ">
                    <div class=" d-flex flex-column justify-content-center">
                        <div class="content">
                            <h2>Streamline Pet Care, Anytime, Anywhere</h2>
                            <p>
                                Welcome to the Petfolio app, your all-in-one
                                solution for pet services and care. From
                                grooming
                                appointments to veterinary consultations, we've
                                got
                                you covered. Easily book pet services, schedule
                                vet
                                appointments, and access expert advice on pet
                                care,
                                all from the convenience of your mobile device.
                                Whether you need a pet sitter, dog walker, or
                                simply
                                want to ensure your furry friend's health and
                                happiness, Petfolio is here to simplify pet care
                                for
                                you. Download now and make caring for your pet a
                                breeze!
                            </p>
                        </div>
                    </div>
                    <div class=" d-flex align-items-center">
                        <img src="../../assets/webassets/img/Group 6848.png"
                            class="img-fluid" alt>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="Services" class="pricing">
        <div class="container">
            <div class="text-center mb-4">
                <h6>Hard to choose right products for your pets?</h6>
                <h4>Our Services</h4>
            </div>

            <div class="row ">
                
                    <div class=" col-sm-12 col-md-6 col-lg-3">
                        <div class="box zoom" style="min-height: 400px;">
                            <img
                                src="../../assets/webassets/img/Group 6868 (6).png"
                                class="img-fluid" width="160px" alt>
                            <div>
                                <h4>Pet Boarding</h4>
                                <p>Safe, Comfortable Pet Boarding
                                    ServicesTrustworthy care while you're
                                    away</p>
                            </div>
                        </div>
                    </div>

                    <div class=" col-sm-12 col-md-6 col-lg-3">
                        <div class="box" style="min-height: 400px;">
                            <img
                                src="../../assets/webassets/img/Group 6869 (1).png"
                                class="img-fluid" width="160px" alt>
                            <div>
                                <h4>Pet Training</h4>
                                <p>Expert Pet Training ServicesUnlock your pet's
                                    full potential.</p>
                            </div>
                        </div>
                    </div>
                    <div class=" col-sm-12 col-md-6 col-lg-3">
                        <div class="box" style="min-height: 400px;">
                            <img src="../../assets/webassets/img/Vector.png"
                                class="img-fluid" width="160px" alt>
                            <div>
                                <h4>Pet Grooming</h4>
                                <p>Professional Pet Grooming
                                    ServicesTransforming
                                    your pet into a star.</p>
                            </div>
                        </div>
                    </div>
                    <div class=" col-sm-12 col-md-6 col-lg-3">
                        <div class="box" style="min-height: 400px;">
                            <img
                                src="../../assets/webassets/img/Group 6870 (1).png"
                                class="img-fluid" width="160px" alt>
                            <div>
                                <h4>Pet Daycare</h4>
                                <p>Fun-Filled Pet Daycare ServicesA home away
                                    from
                                    home for your pet.</p>
                            </div>
                        </div>
                    </div>
                
            </div>
        </div>
    </section>

    <!-- End Pricing Section -->
    <section id="about" class>
        <div class="container download">
            <div class="row  gx-0">
                <!-- <div class="grid-container"> -->
                    <div class=" m-0 col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
                        <img src="../../assets/webassets/img/person.png"
                            class="img-fluid" width="550px" alt>
                    </div>
                    <div
                        class=" mt-3 col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center justify-content-center "
                        style="background: #f5f9ff;">
                        <div class="content">
                            <div class="text-center">
                                <h2 class="mb-4">Download Our App Now!!!</h2>
                                <a href="index.html">
                                    <img
                                        src="../../assets/webassets/img/Google Play (2).png"
                                        width="142px" class="img-fluid p-0 mb-2"
                                        alt>
                                </a>
                                <a href="index.html">
                                    <img
                                        src="../../assets/webassets/img/apple (1).png"
                                        width="142px" class="img-fluid p-0 mb-2"
                                        alt>
                                </a>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </section>
</main>

<!-- ======= Footer ======= -->
<footer id="footer" class="footer pb-0">

    <div class="footer-top">
        <div class="container">
            <div class="row ">
                <!-- <div class="col-12 "> -->
                    <div class="col-12 col-lg-4 order-2">

                        <div class="d-flex justify-content-between wrap">
                            <h4><a class=" scrollto active mb-0"
                                    href="#hero">Home</a></h4>
                            <h4><a class=" scrollto mb-0"
                                    href="#about">About</a></h4>
                            <h4><a class=" scrollto mb-0"
                                    href="#Features">Features</a></h4>
                            <h4><a class=" scrollto mb-0"
                                    href="#Services">Services </a></h4>

                        </div>

                        <div class="text-center">
                            &copy; Copyright
                            <strong><span>Petfolio</span></strong>. All
                            Rights Reserved
                        </div>

                    </div>
                    <div class="col-12 col-lg-4 order-1">

                        <!-- <div class="text-center  mt-3">
                            <a href="index.html">
                                <div class="text-center">
                                    <img
                                        src="../../assets/webassets/img/logo 2.png"
                                        alt>
                                    <span class=" mt-3 ml-2">Petfolio</span>
                                </div>
                            </a>
                        </div> -->
                        <div class="contact-us">
                            <h3 class="text-dark">Contact Us</h3>
                            <ol class="pl-0">
                                <li >Email Info: <span class="text-dark">carpeinfinitus@gmail.com</span></li>
                                <li>Phone: <span class="text-dark"><b>+91</b> 9789018818</span></li>
                            </ol>
                        </div>

                    </div>
                    <div class="col-12 col-lg-4 order-3">

                        <div class="social-links text-center m-2">
                            <a href="#" class="twitter"><i
                                    class="bi bi-twitter"></i></a>
                            <a href="#" class="facebook"><i
                                    class="bi bi-facebook"></i></a>
                            <a href="#" class="instagram"><i
                                    class="bi bi-instagram"></i></a>
                            <a href="#" class="linkedin"><i
                                    class="bi bi-linkedin"></i></a>
                        </div>

                        <p class="text-center m-2"> <a routerLink="/content/common/terms_of_service" class="terms-policy" >Terms of Service</a> | <a class="terms-policy" routerLink="/content/common/privacy_policy">Privacy Policy</a></p>

                    </div>
                <!-- </div> -->

            </div>
        </div>
    </div>

</footer>

<div class="modal fade bd-example-modal-lg " tabindex="-1"
    role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg card" style="background: white;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="exampleModalLongTitle">Petfolio
                    Partners</h5>
                <button type="button" style="float: right;"
                    class="close btn" data-dismiss="modal"
                    aria-label="Close">
                    X
                </button>
            </div>
            <div class="modal-body">
                <section id="pricing" class="pricing p-1">
                    <div class="container">
                        <div class="row">
                            <!-- <div class="col-12 d-flex"> -->
                                <div class="col-12 col-sm-12 col-lg-4 col-md-6">
                                    <div class="box">
                                        <img
                                            src="../../assets/webassets/img/animal (1).png"
                                            class="img-fluid" alt>
                                        <div>
                                            <h4>Veterinarians</h4>
                                        </div>
                                        <a
                                            href="https://petfolio.in:8080/#/admin"
                                            target="_blank" class="btn-buy mt-2"
                                            style="width:80%">Login</a>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-lg-4 col-md-6">
                                    <div class="box">
                                        <img
                                            src="../../assets/webassets/img/technical-service (1).png"
                                            class="img-fluid" alt>
                                        <div>
                                            <h4>Service Providers</h4>
                                        </div>
                                        <a href="index.html">
                                            <img
                                                src="../../assets/webassets/img/Google Play (2).png"
                                                width="83px"
                                                class="img-fluid p-0 mb-2 mr-1"
                                                alt>
                                        </a>
                                        <a href="index.html">
                                            <img
                                                src="../../assets/webassets/img/apple (1).png"
                                                width="83px"
                                                class="img-fluid p-0 mb-2" alt>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-lg-4 col-md-6">
                                    <div class="box">
                                        <img
                                            src="../../assets/webassets/img/vendor (1) 1.png"
                                            class="img-fluid" alt>
                                        <div>
                                            <h4>Vendors</h4>
                                        </div>
                                        <a href="index.html">
                                            <img
                                                src="../../assets/webassets/img/Google Play (2).png"
                                                width="83px"
                                                class="img-fluid p-0 mb-2 mr-1"
                                                alt>
                                        </a>
                                        <a href="index.html">
                                            <img
                                                src="../../assets/webassets/img/apple (1).png"
                                                width="83px"
                                                class="img-fluid p-0 mb-2" alt>
                                        </a>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </section>
            </div>

        </div>
    </div>
</div>
<a href="#"
    class="back-to-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>

<!-- Vendor JS Files -->
<script
    src="../../assets/webassets/vendor/purecounter/purecounter_vanilla.js"></script>
<script src="../../assets/webassets/vendor/aos/aos.js"></script>
<script
    src="../../assets/webassets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script
    src="../../assets/webassets/vendor/glightbox/js/glightbox.min.js"></script>
<script
    src="../../assets/webassets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
<script
    src="../../assets/webassets/vendor/swiper/swiper-bundle.min.js"></script>
<script
    src="../../assets/webassets/vendor/php-email-form/validate.js"></script>

<!-- Template Main JS File -->
<script src="../../assets/webassets/js/main.js"></script>
<style>
    
  </style>

<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
    integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
    crossorigin="anonymous"></script>
<script
    src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
    integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
    crossorigin="anonymous"></script>
<script
    src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
    integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
    crossorigin="anonymous"></script>
