<h4 class="title">Service Provider Specialization</h4>

<div class="card-box">
    <div class="row" style="padding: 0px 0px 23px 10px;">
        <div class="col-md-3">
            <h5 class="form-lable">Title:</h5>
            <input type="text" pInputText [(ngModel)]="Tittle" placeholder="Enter Title">
        </div>
        <div class="col-md-3">
            <h5 class="form-lable">Image:</h5>
            <input type="file" #imgType (change)="fileupload($event)" id="imgfile" accept=".png, .jpg, .jpeg"
                class="form-control" id="city" placeholder="User type icon">
            <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with
                200 * 200 (Max 60 kB)</div>
            <img *ngIf="img_path != undefined" src="{{img_path}}" style="width: 20px;">

        </div>
        <div class="col-md-2">
            <button type="button" *ngIf="edit_t == false" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
                (click)="create()">Add</button>
                <button *ngIf="edit_t == true" type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
                (click)="update()">Update</button>
                <button *ngIf="edit_t == true" type="button" class="btn btn-danger mt-4" (click)="cancel()">
                    Cancel</button>
        </div>

    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="search-hero">
            <div class="input-group mb-3">
               <div class="input-group-prepend">
                 <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
               </div>
               <input class="form-control" type="text" name="search2" 
           [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
             </div>
         </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <h5 class="form-lable">Start Date</h5>
        <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon"></p-calendar>
    </div>
    <div class="col-md-4">
        <h5 class="form-lable">End Date</h5>
        <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon"></p-calendar>
    </div>
    <div class="col-md-4">
        <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
            (click)="filter_date()">Search</button>
            <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
            (click)="refersh()">Refresh</button>
    </div>
</div>
<p-table [value]="list | filter:searchQR" styleClass="ui-table-customers" #tt [paginator]="true" [rows]="5"
    [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['type']">
    <!-- <ng-template pTemplate="caption">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="50">
                <h5 style="color:#fff;">Entry Type List</h5>
            </div>
            <div fxFlex="50">
                <div class="ui-table-globalfilter-container">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Entry Type">
                </div>
            </div>

        </div>

    </ng-template> -->
    <ng-template pTemplate="header">
        <tr class="thhead">
            <th class="th-1">
                S.No
            </th>
            <th>
                Image
            </th>
            <th>
                Title
            </th>
            <th>Created by</th>
            <!-- <th>Modified by</th>
            <th>Created at</th> -->
            <!-- <th>Modified at</th> -->
            <th style="width: 163px;text-align:center">
                Action
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr class="tdrow">
            <td>{{i+1}}</td>
            <td>
                <img src="{{item.img_path}}" alt=""style="width: 50px;height:50px">
            </td>
            <td>
                {{item.img_title}}
            </td>
            <td>
                {{item.date_and_time}}
            </td>
            <td class="act">
                <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" pTooltip="Edit" (click)="edit(item)"></i>
                <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete" (click)="delete(item._id)"></i>
                <!-- <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true" pTooltip="View"></i> -->
            </td>
        </tr>
    </ng-template>
</p-table>