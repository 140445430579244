<section style="text-transform:capitalize">
    <div class="page-header ">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Product Management</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Product Managment</li>
            </ol> -->
        </div>
        <!-- <div class="d-flex">
            <button class="btn btn-sm btn-secondary " (click)="back()"> <i class="fas fa-arrow-left ml-1"
                    aria-hidden="true"></i> Back</button>
        </div> -->
    </div>


    <div class="row row-sm">
        <div class="col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="card-item">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="search-hero">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <input class="form-control" type="text" name="search2" [(ngModel)]="searchQR"
                                            autocomplete="off" placeholder="Search...">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-10 text-center">
                                <p-calendar [(ngModel)]="S_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon"
                                    placeholder="Start Date" class="mr-3"></p-calendar>
                                <p-calendar [(ngModel)]="E_Date" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" placeholder="End Date"
                                    class="mr-3"></p-calendar>
                                <button type="button" class="btn btn-primary" (click)="filter_date()"><i
                                        class="fas fa-search"></i> Search</button>
                                <!-- </div>
                            <div class="col-md-1 text-right"> -->
                                <button type="button" class="btn btn-secondary" (click)="refersh()"><i
                                        class="fas fa-sync-alt"></i> Refresh</button>
                                <!-- </div>
                            <div class="col-md-1 text-right"> -->
                                <button type="button" class="btn btn-primary" (click)="addproduct_details()"><i
                                        class="fas fa-plus"></i> Add Product</button>
                            </div>
                        </div>

                        <!-- search -->
                        <div class="doc-table table-responsive">
                            <p-table [value]="list | filter:searchQR" styleClass="ui-table-customers" #tt
                                [paginator]="true" [rows]="5" [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['type']">
                                <!-- <ng-template pTemplate="caption">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="50">
                <h5 style="color:#fff;">Entry Type List</h5>
            </div>
            <div fxFlex="50">
                <div class="ui-table-globalfilter-container">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Entry Type">
                </div>
            </div>

        </div>

    </ng-template> -->
                                <ng-template pTemplate="header">
                                    <tr class="thhead">
                                        <th class="th-1">
                                            S.No
                                        </th>
                                        <th >
                                            thumbnail image
                                        </th>
                                        <th >
                                            Product Image
                                        </th>
                                        <th >
                                            Product Name
                                        </th>
                                        <th >
                                            Category
                                        </th>
                                        <th>Today's Deal</th>
                                        <th>shop Name</th>
                                        <th>User Name</th>
                                        <th>Phone Number</th>
                                        <th>Created at</th>
                                        <th style="width: 163px;text-align:center">
                                            Action
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item let-i="rowIndex">
                                    <tr class="tdrow" style="text-transform:none">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <div class="imgthumcontainer"><img src="{{item.thumbnail_image}}"></div>
                                        </td>
                                        <td>
                                            <div class="imgthumcontainer"><img
                                                    src="{{item.product_img[0].product_img}}"></div>
                                        </td>
                                        <td>{{item.product_name}}</td>
                                        <td>{{item.cat_id?.product_cate}}</td>
                                        <td>
                                            <button class="btn btn-sm btn-success makeDealbtn"
                                                *ngIf="item.today_deal === false"
                                                (click)="makeTDeal(item._id, true)">Make Today's Deal</button>
                                            <button class="btn btn-sm btn-danger makeDealbtn"
                                                *ngIf="item.today_deal === true"
                                                (click)="makeTDeal(item._id, false)">Cancel Today's Deal</button>
                                        </td>
                                        <td>{{item.user_id.bussiness_name}}</td>
                                        <td>{{item.user_id.user_name}}</td>
                                        <td>{{item.user_id.bussiness_phone}}</td>
                                        <td>
                                            {{item.createdAt | date:'dd-MM-yyyy'}}
                                        </td>
                                        <td class="act text-center">
                                            <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true"
                                                pTooltip="Edit" (click)="editproduct_details(item)"></i>
                                            <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true"
                                                pTooltip="Delete" (click)="delete(item._id)"></i>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" >
                                    <tr class="full text-center" *ngIf="isLoading" >
                                        <td colspan="10" >
                                          <div class="custom-flex">
                                          <img class="img-fluid loader-logo" src="assets/images/dogloader.gif" alt="loader" > <div class="fs">Loading....</div> </div>
                                        </td>   
                                      </tr>
                                    <tr class="full text-center" *ngIf="list.length==0 && !isLoading">
                                        <td colspan="10" >
                                          <div class="custom-flex">
                                                <img class="img-fluid loader-logo" src="assets/images/nodataimage.png" alt="Logo" >
                                               <div class="fs">No records found</div> </div>
                                        </td>
                                      </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" >
                                    <tr  *ngIf="list?.length !=0 && !isLoading">
                                        <td colspan="10"> <p class="filter-data ">No results match your search " <span>{{searchQR}}</span> ".</p> </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


</section>