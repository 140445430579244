<section>
    <!-- <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Pet Handled</h2>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol>
        </div>
        
    </div> -->

    <ng-template #addedDialog>
        <div class="text-center">
          <i class="fas fa-check-circle text-success fa-5x"></i>
          <p class="lead mt-3 mb-3 font-weight-bold">Added Successfully</p>
          <button class="btn btn-primary btn-sm" style="height: inherit;" matDialogClose >Close</button>
        </div>
      </ng-template>
      
      <ng-template #updateDialog>
          <div class="text-center">
            <i class="far fa-check-circle text-success fa-5x"></i>
            <p class="lead mt-3 mb-3 font-weight-bold">Updated Successfully</p>
            <button class="btn btn-primary btn-sm" style="height: inherit;" matDialogClose >Close</button>
          </div>
        </ng-template>

        <ng-template #deleteDialog>
            <div class="text-center">
              <i class="far fa-check-circle text-danger fa-5x"></i>
              <p class="lead mt-3 mb-3 font-weight-bold">Deleted Successfully</p>
              <button class="btn btn-primary btn-sm" style="height: inherit;" matDialogClose >Close</button>
            </div>
          </ng-template>
    
          
<div class="row row-sm">

    <div class="col-lg-12">
<div class="card custom-card">
    <div class="card-body">
        <div class="card-item">
            <div class="row">
                <!-- <div class="col-md-4">
                    <h5 class="form-lable">Pet Handled</h5>
                    <input type="text" pInputText name="pet_type_title" [(ngModel)]="pet_type_title" placeholder="Enter Pet Handled">
                </div> -->
                <!-- <div class="col-md-4">
                  <h5 class="form-lable">Pet type icon</h5>
                  <input type="file" #imgType (change)="fileupload($event)" id="imgfile"
                  accept=".png, .jpg, .jpeg" class="form-control" id="city" placeholder="User type icon">
                  <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with 80 * 80 (Max 100 kB)</div>
                  <img src="{{user_type_img}}" style="width: 15%;">
              </div> -->
                <div class="col-md-4">
                    <button *ngIf="update_button == true" type="button" class="btn btn-primary mt-4" (click)="Insert_pet_type_details()"><i class="fas fa-plus-circle"></i> Add</button>
                    <button *ngIf="update_button == false" type="button" class="btn btn-primary mt-4"  (click)="Edit_pet_type_details()"><i class="fas fa-pen"></i> Update</button>
                    <button *ngIf="update_button == false" type="button" class="btn btn-danger mt-4" (click)="cancel()">
                        Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>

<div class="row row-sm">

    <div class="col-lg-12">
    <div class="card custom-card">
        <div class="card-body">
            <div class="card-item">
                 
                <div class="row">
                    <div class="col-md-4">
                        <div class="search-hero">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                                    </div>
                                    <input class="form-control" type="text" name="search2"
                                [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
                                </div>
                        </div>
                    </div>
                    <div class="col-md-8 text-lg-right text-sm-left">
                        <p-calendar placeholder="Start Date" [(ngModel)]="S_Date" [showIcon]="true" inputId="icon" class="mr-2"></p-calendar>
                        <p-calendar placeholder="End Date" [(ngModel)]="E_Date" [showIcon]="true" inputId="icon" class="mr-3"></p-calendar>
                        <button type="button" class="btn btn-primary mr-2"
                            (click)="filter_date()"><i class="fas fa-search"></i> Search</button>
                            <button type="button" class="btn btn-secondary" (click)="refersh()"><i class="fas fa-sync-alt"></i> Refresh</button>
                    </div>
    
                </div>

                <!-- table -->
                <div class="table-responsive">
                    <p-table [value]="rows | filter:searchQR" styleClass="ui-table-customers" #tt [paginator]="true" [rows]="15"
                        [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['type']">
                        <!-- <ng-template pTemplate="caption">
                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                <div fxFlex="50">
                                    <h5 style="color:#fff;">Entry Type List</h5>
                                </div>
                                <div fxFlex="50">
                                    <div class="ui-table-globalfilter-container">
                                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                        <input type="text" pInputText size="50" (input)="tt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Entry Type">
                                    </div>
                                </div>

                            </div>

                        </ng-template> -->
                        <ng-template pTemplate="header">
                            <tr class="thhead">
                                <th class="th-1">
                                    S.No
                                </th>
                                <!-- <th>
                                    Pet Handled
                                </th> -->
                                <th>Created by</th>
                                <!-- <th>Modified by</th> -->
                                <th>Created at</th>
                                <!-- <th>Modified at</th> -->
                                <th style="width: 163px;text-align:center">
                                    Action
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item  let-i="rowIndex">
                        <!-- <ng-container *ngFor="let item of user_type_list ; let i = index;"> -->
                        <tr class="tdrow">
                                <td>{{i+1}}</td>
                                <!-- <td>{{item.pet_type_title}}</td> -->
                                <!-- <td>
                                <img _ngcontent-dah-c159="" src="{{item.user_type_img}}" alt="Denim Jeans" style="width: 20%;">
                            </td> -->
                                <td>
                                    Admin
                                </td>
                                <td>
                                {{item.date_and_time}}
                                </td>
                                <td class="act">
                                    <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" (click)="Editcompanydetailsdata(item)" pTooltip="Edit"></i>
                                    <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete" (click)="Deletecompanydetails(item._id)"></i> -->
                                    <!-- <i style="color: #4CAF50;" class="fa fa-info-circle" aria-hidden="true" pTooltip="View"></i> -->
                                </td>
                            </tr>
                        <!-- </ng-container> -->
                        </ng-template>
                    </p-table>

                </div>

            </div>
        </div>
    </div>
    </div>
</div>


<!-- <div class="row">
    <div class="col-md-4">
        <div class="search-hero">
            <div class="input-group mb-3">
               <div class="input-group-prepend">
                 <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
               </div>
               <input class="form-control" type="text" name="search2"
               [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
             </div>
      </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <h5 class="form-lable">Start Date</h5>
        <p-calendar [(ngModel)]="S_Date" [showIcon]="true" inputId="icon"></p-calendar>
    </div>
    <div class="col-md-4">
        <h5 class="form-lable">End Date</h5>
        <p-calendar [(ngModel)]="E_Date" [showIcon]="true" inputId="icon"></p-calendar>
    </div>
    <div class="col-md-4">
        <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
            (click)="filter_date()">Search</button>
            <button type="button" class="btn btn-primary" style="margin-left: 10px;margin-top: 24px;"
            (click)="refersh()">Refresh</button>
    </div>
</div> -->

</section>