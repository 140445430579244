import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctorappointmentview',
  templateUrl: './doctorappointmentview.component.html',
  styleUrls: ['./doctorappointmentview.component.css']
})
export class DoctorappointmentviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
