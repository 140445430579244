
<section>
    <div class="page-header">
        <div>
            <h2 class="main-content-title tx-24 mb-1">Sub-Category Management</h2>
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="dashboard">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Details</li>
            </ol> -->
        </div>
  
    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
<div class="card custom-card">
        <div class="card-body">
            <div class="card-item">
                <div class="row">
                    <div class="col-md-3" style="margin-bottom: 15px;">
                        <h5 class="form-lable">Category:</h5>
                        <p-dropdown [options]="Catagories_list" [(ngModel)]="Category" placeholder="Select Category"
                            optionLabel="product_cate" [showClear]="true"></p-dropdown>
            
                    </div>
                    <div class="col-md-3">
                        <h5 class="form-lable">Sub-Category:</h5>
                        <input type="text" pInputText [(ngModel)]="Sub_Category" placeholder="Enter Sub-Category">
                    </div>
                    <!-- <div class="col-md-4">
                        <h5 class="form-lable">sub-Category Code:</h5>
                        <input type="text" pInputText [(ngModel)]="value1" placeholder="Enter sub-Category Code">
                    </div> -->
                    <div class="col-md-4">
                        <h5 class="form-lable">Category Icon:</h5>
                        <input type="file" #imgType (change)="fileupload($event)" id="imgfile" accept=".png, .jpg, .jpeg"
                            class="form-control" id="city" placeholder="User type icon">
                        <div style="font-size: 11px;text-align: right;color: gray;font-weight: 600;">Upload the user type icon with
                            100 * 100 (Max 200 kB)</div>
                            <div class="imgthumcontainer"><img *ngIf="img_path != undefined" src="{{img_path}}"> </div>
            
                    </div>
                    <div class="col-md-2">
                        <button type="button" *ngIf="edit_t == false" class="btn btn-primary mt-4" (click)="create()"><i class="fas fa-plus-circle"></i> Add</button>
                        <button *ngIf="edit_t == true" type="button" class="btn btn-primary mt-4" (click)="update()"><i class="fas fa-edit"></i> Update</button>
                    </div>
            
                </div>
            </div>
        </div>
    </div>
 </div>
    </div>

    <div class="row row-sm">

        <div class="col-lg-12">
<div class="card custom-card">
        <div class="card-body">
            <div class="card-item">
                <div class="row">
                    <div class="col-md-3">
                        <div class="search-hero">
                            <div class="input-group mb-3">
                               <div class="input-group-prepend">
                                 <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                               </div>
                               <input class="form-control" type="text" name="search2"
                           [(ngModel)]="searchQR" autocomplete="off" placeholder="Search...">
                             </div>
                          </div>
                    </div>
                    <div class="col-md-7 text-center">
                        <p-calendar [(ngModel)]="S_Date" [showIcon]="true" inputId="icon" placeholder="Start Date" class="mr-3"></p-calendar>
                        <p-calendar [(ngModel)]="E_Date" [showIcon]="true" inputId="icon" placeholder="End Date" class="mr-3"></p-calendar>
                        <button type="button" class="btn btn-primary" (click)="filter_date()"><i class="fas fa-search"></i> Search</button>
                    </div>
                    <div class="col-md-2 text-right">
                        <button type="button" class="btn btn-secondary" (click)="refersh()"><i class="fas fa-sync-alt"></i> Refresh</button>
                    </div>
                </div>

                <!-- search -->

                <div class="table-responsive doc-table">
                    <p-table [value]="rows | filter:searchQR" styleClass="ui-table-customers" #tt [paginator]="true" [rows]="15"
                    [rowHover]="true" [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['type']">
                
                    <ng-template pTemplate="header">
                        <tr class="thhead">
                            <th class="th-1">
                                S.No
                            </th>
                            <th>
                                Sub-Category Icon
                            </th>
                            <th>
                                Category
                            </th>
                            <th>
                                Sub-Category
                            </th>
                  
                            <th>Created at</th>
                            <th>Modified at</th>
                            <th style="width: 163px;text-align:center">
                                Action
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                        <tr class="tdrow">
                            <td>{{i+1}}</td>
                            <td>
                                <div class="imgthumcontainer"><img _ngcontent-dah-c159="" src="{{item.img_path}}" alt=""> </div>
                            </td>
                            <td>{{item.product_categ}}</td>
                            <td>{{item.product_sub_cate}}</td>
                            <td> {{item.createdAt}}
                            </td>
                            <td>
                                {{item.updatedAt}}
                            </td>
                            <td class="act">
                                <i style="color: #2196f3;" class="fas fa-edit" aria-hidden="true" pTooltip="Edit"
                                    (click)="edit(item)"></i>
                                <!-- <i style="color: #fd2c3a;" class="fa fa-trash" aria-hidden="true" pTooltip="Delete"
                                    (click)="delete(item._id)"></i> -->
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                </div>

                
            </div>
        </div>
    </div>
 </div>
    </div>





</section>